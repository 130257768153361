import { formatNumber } from '../helpers';

const en = {};

en.account = {
  downloadCSV: `Download CSV`,
  downloadSampleCSV: `Download Sample CSV`,
  moreSubscribers: `More subscribers`,
  moreCustomers: `More customers`,
  subscribers: `Subscribers`,
  metrics: 'Metrics',
  recommendation: 'Recommendation',
  promotions: `Promotions`,
  coupons: `Coupons`,
  pricingPlans: `Pricing Plans`,
  activateAnnualPlan: 'Activate annual plan',
  price: 'Price',
  purchases: `Purchases`,
  customers: `Customers`,
  noCard: 'No Card Added',
  addCreditCard: 'Add Credit Card',
  deleteConfirmationMessage: `<p>If you delete the card, your all the subscriptions will be cancelled at period end. If you add new card within your cancellation period all your subscription will be resubscribed. </br> All your donation subscriptions will be cancelled now, to continue donating you need to donate to the creator again after adding new card</p`,
  removeCard: 'Remove Card',
  cardDeletedSuccessfully: 'Card deleted successfully',
  receipts: 'Receipts',
  creditCard: 'Credit Card',
  myReceipts: 'My Receipts',
  invoices: 'Invoices',
  copyright2020: 'Copyright 2020',
  youArePayingForYourSubscriptionsWithThisCreditCard:
    'You are paying for your subscriptions with this credit card',
  delete: 'Delete',
  thisCard: 'this card',
  changeCreditCard: 'Change credit card',
  enterYourDetailsToConfirm: 'Enter your details to confirm',
  dashboard: 'Dashboard',
  episodes: 'Episodes',
  audioInsertion: 'Audio Insertion',
  audios: 'Audios',
  edit: 'Edit',
  hostedBy: 'Hosted by',
  viewPublicSite: 'View Public Site',
  plays: 'Plays',
  totalPlays: 'Total plays',
  type: 'Type',
  earns: 'Earns',
  suscribers: 'Subscribers',
  MRRMonthlyRecurringRevenue: 'MRR: Monthly recurring revenue',
  sales: 'Sales',
  claimYourAmount: 'Claim your amount',
  lastPayment: 'On the way:',
  youCannotClaimPayoutUntil50: 'You cannot claim payout until 50',
  youCanClaimOnlyIfTheAmountIsMoreThan50:
    'You can claim only if the amount is more than 50',
  requestForPayout: 'Request for payout',
  myPodcasts: 'My Podcasts',
  myAudios: 'My Audios',
  discoverBestAudioContent: 'Discover the best audio content',
  allContent: 'All content',
  allCategories: 'All Categories',
  mySubscriptions: 'My Subscriptions',
  myPurchases: 'My Purchases',
  youHaveNotSubscribedToAnyPodcast: 'You have not subscribed to any podcast',
  createPodcast: 'Create a podcast',
  create: 'Create',
  totalNumberOfUniquePlays: 'Total number of unique plays:',
  newEpisode: 'New episode',
  newAudio: 'New Audio',
  addAudio: 'Add Audio',
  editEpisode: 'Edit episode',
  editAudio: 'Edit audio',
  moreEpisodes: 'More episodes',
  moreAudios: 'More audios',
  emailConfirmation:
    'A confirmation email has been sent to your inbox. Follow the link in the email to complete your registration.',
  alreadyAccount: 'Already have an account?',
  profileUpdateHeading: 'Profile updated',
  profileUpdateDescription: 'Your profile has been successfully updated',
  deleteAccountPrompt: 'Are you sure you want to delete your account?',
  deleteAccountHeading: 'Done',
  deleteAccountDescription: 'Your account will be deleted within the next 24h',
  deleteAccountButton: 'Delete account',
  updateProfileButton: 'Update profile',
  yourNameLabel: 'Your name',
  linksRestricted: 'Links are not allowed in description',
  descriptionLabel: 'Description',
  country: 'Country',
  countryError: 'Please select your country',
  // bankAccountHeading: 'Your bank account',
  // bankAccountDescription: 'So we can make you payments from your subscribers',
  whereDoWePayYou: 'Where do we pay you',
  bankAccountDescription:
    "If you have EU bank account, you can provide us  your account's IBAN number.",
  ibanNumberLabel: 'Your IBAN Number',
  ibanError: 'Invalid IBAN Number',
  payPalAccountDescription:
    "We can also pay you via PayPal. Add the email associate to your PayPal account and we'll send you the money there.",
  payPalNumberLabel: 'Email of your PayPal account',
  payPalError: 'Invalid email',
  vatNumberHeading: 'Tax details',
  vatNumberLabel: 'VAT Number',
  billingNameLabel: 'Billing Name',
  billingAddressLabel: 'Blling Address',
  vatError: 'Invalid VAT Number',
  passwordHeading: 'Change your password',
  choosePasswordLabel: 'Please choose a password',
  passwordError: 'Password must have at least 8 characters',
  passwordChangeTitle: 'Password Changed Successfully',
  passwordChangeDescription: 'Password has been reset with the new password',
  confirmPasswordError: 'Passwords do not match',
  oldPasswordLabel: 'Enter your old password',
  newPasswordLabel: 'New password',
  repeatPasswordLabel: 'Repeat password',
  forgotPasswordConfirm: 'Check your inbox for the reset link',
  passwordResetEmailHasBeenSent: 'Password reset e-mail has been sent.',
  resetPasswordButton: 'Reset password',
  termsAgree: 'I agree to the',
  termsError: 'Please accept our Terms and Conditions',
  termsAnchorText: 'terms and conditions',
  newsLetter: 'I agree to receive product updates and Mumbler newsletter',
  startMyPodcast: 'Start my podcast',
  startMyAudio: 'Start',
  registerTitle: 'Register and continue to payment',
  login: 'Login',
  facebookLogin: 'Login Facebook',
  addDomainToViewPublicSite:
    'You have not added domain for this user. Please add domain in creator settings to view public site.',
  addDomainToCreatePodcast:
    'You have not added domain for this user. Please add domain in creator settings to create a podcast.',
  goToCreatorSettings: 'Go to Creator Settings',
  donors: 'Donors',
};
en.common = {
  schedule: 'Schedule',
  publish: 'Publish',
  ok: 'Ok',
  renewBtn: 'Renew Subscription',
  or: 'or',
  shareText: ({ title }) => `Listen to my #podcast ${title}`,
  sharePodcast: `Share this Podcast`,
  shareAudio: `Share this Audio Series`,
  addCard: 'Add card',
  cardAdded: 'Card Added',
  cardAddedSuccessfully: 'Card added successfully',
  perMonth: 'per month',
  month: 'month',
  done: 'Done!',
  areYouSure: 'Are you sure?',
  cancel: 'Cancel',
  close: 'Close',
  share: 'SHARE',
  sure: `Yes, I'm Sure`,
  save: 'Save',
  getStarted: 'Signup Free',
  welcome: 'Welcome',
  goinside: 'Go inside',
  pricing: 'MONETIZE',
  'pricing&Features': 'Pricing & Features',
  mumblerIsTheEasiestWayToCreateASubscriptionPodcast:
    'Mumbler is the easiest way to create a subscription-based podcast',
  ofTheMonthlyIncomeYouEarnOnMumbler:
    'of the monthly income you earn on Mumbler',
  allIncluded: 'ALL INCLUDED',
  podcastHosting: 'Hosting for your podcast',
  podcastLandingPage: 'Webpage for your podcast',
  highQualityAudio: 'High-quality audio',
  insights: 'Statistics',
  prioritySupport: 'Priority support',
  paymentProcessing: 'Payment processing',
  faqs: 'FAQs',
  works: 'HOWITWORKS',
  'howItWorks?': 'How does it work?',
  beIndependent: 'Be independent',
  joinMumblerInLessTimeThanItTakesToSipCoffe:
    'Sign up for Mumbler now in the blink of an eye',
  uploadYourFirstEpisode: 'Upload your first episode',
  uploadYourFirstEpisodeToMumbler: 'Upload the first episode of your podcast',
  enjoyYourlanding: 'Get your own page for your podcast ',
  'we’llBuildAnAmazingLandingPageForYourPodcast':
    'We’ll make you an awesome page for your podcast',
  growYourBusiness: 'Make your business grow',
  startSellingYourPremiumPodcastToYourCommunity:
    'Start earning money with your podcast and expand your community of subscribers',
  error: 'Error',
  error404PageNotFound: 'Error 404. Page not found',
  pleaseTryAgainAfterSomeTimeIfYouLandedHereByMistake:
    'Please try again after some time. If you landed here by mistake,',
  letusKnow: 'let us know',
  'An error occurred on server': ({ statusCode }) =>
    `An error ${statusCode} occurred on server`,
  'An error occurred on client': ({ statusCode }) =>
    `An error ${statusCode} occurred on client`,
  clickToUpload: 'Click to upload or drag files here',
  onlyMp3: 'Only mp3 uploads supported',
  audioFileRequired: 'File type must be audio/*',
  fileSizeTooLarge: 'File size too large',
  dropFile: 'Drop your file here',
  dropPicture: 'Drop your profile picture here',
  myMumbler: 'My Mumbler',
  dashboard: 'Dashboard',
  myProfile: 'My Profile',
  billing: 'Billing',
  logout: 'Logout',
  scroll: 'MORE',
  home: 'MUMBLER',
  manifesto: 'MANIFESTO',
  calculadora: 'CALCULADORA',
  team: 'TEAM',
  yourName: 'Your name',
  audioInsertionName: 'Audio insertion name',
  promoName: 'Promo name',
  yourNameError: 'Please enter your name',
  unsubscribe: 'Unsubscribe',
  subscribe: 'Subscribe',
  emailAddress: 'Email address',
  emailRequired: 'Email address is required',
  emailLabel: 'Please enter your email address',
  passwordLabel: 'Password',
  forgotPassword: 'Forgot your password?',
  passwordError: 'Please enter your password',
  invalidEmailError: 'Please enter a valid email address',
  subscriptionEnd: ({ date }) => `Subscription ending on ${date}`,
  unsubscribeQuestion: ({ podcast, date }) =>
    `You are about to cancel your subscription to ${podcast}. If you do, then after ${date} you will lose access to all the episodes they published, and also any newly released episodes published by ${podcast}.`,
  renewText: ({ date }) =>
    `You have canceled this subscription, and you will not be able to listen to this podcast when your current subscription terminates on ${date}. If you don’t want to lose access to these episodes, you should subscribe again.`,
  unsubscribedTitle: 'Unsubscribed',
  unsubscribedmessage: 'You have successfully unsubscribed from this podcast.',
  messageSentTitle: 'Message Sent',
  messageSentDescription:
    'We have received your message and we’ll get back to you soon.',
  messageSent: 'Your message has been sent.',
  thankYou: 'Thank You!',
  verificationLinkMessage:
    'A verification link has been sent to your email account.',
  accountCreationMessage:
    'Your account has been created, let’s create your first podcast.',
  accountCreationMessageAudio: 'Your account has been created.',
  loginButton: 'Login',
  settings: 'Settings',
  creatorPage: 'Creator page',
  privacyPolicy: 'Privacy Policy',
  termsAndConditions: 'Terms and Conditions',
  search: 'Search',
  nameIsRequired: 'Name is required',
  emailIsRequired: 'Email is required',
  next: 'Next',
  width: 'Width',
  height: 'Height',
  add: 'Add',
  embedURL: 'Embed URL',
  videoURL: 'Video URL',
  previous: 'Previous',
  pleaseCropImage: 'Please Crop Image',
  renewSubscription: 'Renew subscription',
  changesSavedSuccessfully: 'Changes saved successfully',
  scheduled: 'Scheduled',
  invalidSlugName: 'Invalid slug name',
  warning: 'Warning',
  products: 'Products',
  audience: 'Audience',
  newsletter: 'Newsletter',
  myFeed: 'My Feed',
  discover: 'Discover',
  emailUserInvalid:
    'Sorry, only letters (a-z), numbers (0-9) and full stops (.) are allowed.',
  thanks: 'Thanks!',
  nowYouCanEnjoyAllMumblerFeatures:
    'Now you can enjoy all Mumbler features.',
  congratulations: 'Congratulations!',
  startThankYouBtn: 'Start',
  logoutForSecurityPurpose: 'Logout for security purpose',
  youRegisteredUsingGoogleOrFacebookButton:
    'You registered using Google or Facebook button.',
  createAudioInsertion: 'Create Audio Insertion',
  audioInsertionDateConflicts:
    'This podcast already has a campaign for the same dates.',
  couponRemove:'If you delete a code it does not work anymore.',  
};
en.footer = {
  copyright: ({ year }) => `Copyright ${year}`,
  home: 'Home',
  manifesto: 'Manifesto',
  team: 'Team',
  discover: 'Discover',
  podcast: 'Podcast',
  legal: 'Legal',
  termsAndConditions: 'Terms and Conditions',
  help: 'Help',
  blog: 'Blog',
  features: 'Features',
  pricing: 'Pricing',
  creatorStories: 'Creator stories',
  webinars: 'Webinars',
  resources: 'Resources',
  podcastHosting: 'Podcast hosting',
  affiliateProgram: 'Affiliate program',
};
en.helpError = {
  help: 'HELP',
  helpDescription:
    'We’re here to help. Let us know what’s the matter, and we will get back to you ASAP.',
  Help: 'Help',
  yourEmailAddress: 'Your email address *',
  typeHere: 'Write here *',
  send: 'Send',
  error: 'Error',
  somethingWentWrong: 'Something went wrong',
  youAreNotAuthorizedToVisitThisPageFromYourMumblerAccount:
    'You are not authorized to visit this page from your Mumbler account.',
  ifYouLandedHereByMistake: 'If you landed here by mistake,',
  goToHome: 'Go to Home',
};
en.home = {
  titleTag: 'Mumbler – Sell your audios the easy way',
  create: 'CREATE',
  createYourSubscriptionPodcast: ({ changingText }) =>
    `Monetize your audience with ${changingText}`,
  createYourPodcastBusinessTheEasyWay:
    'In less than 1 minute and just a click away.',
  beFreeBeAProfessionalPodcaster: 'We only earn money when you do.',
  beJustYou: 'Be Just You.',
  audioPodcasts: 'Audio podcasts',
  playPodcast: 'Play podcast',
  on: 'ON',
  mumbler: 'MUMBLER',
  youCanReceiveMonthlyPayments: 'Monetize your listeners',
  createASubscriptionPodcast: 'Create a subscription model',
  inMinutes: 'in just a few minutes',
  'giveAccesToYourEpisodesAndRecieveMonthlyPaymentFromYourAudienceThisIsNotAboutDonationsIt’sAboutToBuildABusiness':
    '. Give access to your episodes and receive monthly payments from your listeners. This isn’t about donations, it is about creating a business.',
  'whyAPodcastMembership?': 'Start selling your audios',
  whyToCreateABusinessWithYourPodcast:
    'We make it easy to earn money by selling audio contents, podcasts and more',
  be: 'Fast',
  independent: 'and secure',
  runYourOwnBusinessNotReliantOnAdsOrPaidSponsorshipBeFreeBeIndependent:
    'Design your business without commercials or sponsors. Be independent and have the freedom to choose.',
  get: 'Get',
  paidToDoWhatYouLove: 'paid for doing what you love',
  beTheProfesionalPodcasterYouAlwaysWantToBeYourPodcastIsYourBusiness:
    'Be a professional audio creator just like you always wanted. Your content is your business.',
  build: 'Build',
  yourAudience: 'your audience',
  getAccessToYourFansDirectlyAndBuildAStrongerCommunity:
    'Give direct access to your listeners and build a thriving community of subscribers.',
  featured: 'Featured',
  notablePodcastersAndAudioOnMumbler:
    'This are some of the coolest podcasts and audios on Mumbler',
  loadMore: 'Load More',
  mumblerIcon: 'Mumbler icon',
  greatPodcastsAreValuable: 'Start earning money now',
  listenersPayDirectlyForPodcastsTheyCareAboutStartAcceptingPaidSubscriptionsInJustAFewMinutes:
    'Start charging for your podcast, audio-course, audio series or any other audio content. It is just a click away.',
};
en.calculadora = {
  calculadora: 'Calculadora',
  ourCalculator: 'Nuestra Calculadora',
};
en.manifest = {
  manifesto: 'MANIFESTO',
  ourManifesto: 'Our manifesto',
  whyToChooseMumbler: 'Why choose Mumbler?',
  value: 'Everything’s got a price',
  podcastsLikeAllContentHaveALotOfValueEvenIfYouAreNotPayingForIt:
    'Even when you aren’t paying for it, podcasts offer a lot of value to the listeners.',
  option: 'Earn a living from podcasting',
  podcastersLikeAllContentCreatorsMustHaveTheOptionToLiveFromPodcasting:
    'Podcasters should have the option to make a living from the value they share podcasting.',
  noAdditionalGifts: 'With no additional gifts',
  aPodcastIsRewardEnoughForYourListenersNoAdditionalGiftsAreNecessaryToJustifyTheirSupport:
    'Your podcast itself should be enough for your listeners. It shouldn’t take additional incentives to get them to subscribe.',
  mumblersMission: 'Our Mission',
  atMumblerWeHaveAMissionHelpPodcastersLiveFromTheirContent:
    'We are on a mission: to help you make a living off your podcast.',
  weWantToLiveFromPodcasting: 'We want to make a living from our podcasts',
  weArePodcastersAndWeWantToLiveFromPodcastingForThisReasonWeAreTheFirstToUseMumbler:
    'We are podcasters, and we want to make a living off of our podcasts. That’s why we are the first to use Mumbler.',
  builtByListening: `We’ll listen to you`,
  mumblerIsBuiltByListeningToOurCustomersIfAPodcasterProposesAnIdeaAndWeSeeThatItCanHelpManyOtherPodcastersWeIncludeItInOurRoadmap:
    'Mumbler is built on listening to our users. We created our roadmap based on your needs.',
  addingValueToPodcasters: 'Better assistance and more help',
  mumblerOnlyMakesSenseIfWeAddValueToPodcastersAllOurDecisionsAreAimedAtHelpingOurClientsMoreAndBetter:
    'All our decisions are oriented to provide you better assistance and more help.',
  yourOwnershipStaysYours: 'What’s yours will keep being yours',
  theContentAndItsSubscribersAreAlwaysFromThePodcasterWeOnlyHelpToMonetizeItIfYouWantToStopUsingMumblerWeWillGiveYouTheToolsToTakeYourContentContactsAndWeWishYouGoodLuckWhereverYouGo:
    'The content and the subscribers are yours, we just help you to monetize your podcast. If you would like to stop using Mumbler, we will give you the tools so that you can take your content/subscribers with you wherever you want to go with all the best from the Mumbler team.',
  transparent: 'We’re up-front and honest',
  weAreTransparentWeTellYouThingsAsTheyAre:
    'We’ll give you the info just as it is.  exactly how it is.',
  solutionFocussed: 'We’re human',
  weArePeopleAndWeMakeMistakesWhenThisHappensWeAcceptItAskForForgivenessAndFindSolutions:
    'We make mistakes, and when it happens we accept them, say sorry, and find a solution.',
};
en.podcast = {
  pubEpisodeErrorTitle: "You've reached the maximum free episodes",
  pubEpisodeErrorInfo:
    "You can't publish more public episodes than private episodes. If you want to publish a new public episode, you have to publish a new private one or change one of the public episodes you already have to private. ",
  copyPrivateRSSLink: 'Copy Private RSS Link',
  podcatcherWarning: `Beware! Don't share your private feed URL as it contains some parameters that can lead to an unauthorized access of your account.`,
  renewTitle: 'Subscription Renewed',
  renewMsg: 'You have successfully renewed subscription to this podcast.',
  txtColor: 'Text color',
  btnColor: 'Button color',
  username: 'username',
  website: 'website.com',
  noPromoAvailable:"No Promo available for recommendation",
  priceRange: ({ from, to }) => `Price: ${from} to ${to}`,
  priceRangeAudioSeries: ({ from }) => `Price: greater or equal to ${from}`,
  maxPriceLimit: (maxPrice) => `Price up to ${maxPrice}`,
  play: 'Play',
  hostedBy: 'Hosted by',
  contactPodcaster: 'Contact Podcaster',
  trailer: 'Trailer',
  readMore: 'Read more',
  readLess: 'Read less',
  previousRecommendation: "Previous Recommendations",
  latestBlogPosts: 'Latest Blog Posts',
  episodes: 'Episodes',
  noEpisodesFound: 'No Episodes Found',
  noAudiosFound: 'No Audios Found',
  free: 'Free',
  onlyAvailableForSubscribersSubscribe:
    'You are just one click away from enjoying it. Subscribe',
  onlyAvailableForPurchaserCustomers:
    'You are just one click away from enjoying it. Purchase',
  now: 'now',
  moreEpisodes: 'More episodes',
  moreAudios: 'More audios',
  send: 'SEND',
  typeYourMessageHere: 'Type your message here *',
  howToAddToYourPodcatcher: 'How to add to your podcatcher',
  addToYourPodcatcher: 'Add to your podcatcher',
  applePodcasts: 'Apple Podcasts',
  overcast: 'Overcast',
  pocketCasts: 'Pocket Casts',
  googlePodcasts: 'Google Podcasts',
  podcastGuru: 'PodcastGuru',
  podcastAddict: 'podcastAddict',
  spotify: 'Spotify',
  ivoox: 'Ivoox',
  youtube: 'Youtube',
  editPodcast: 'Edit podcast',
  editAudioSeries: 'Edit audio series',
  createAPodcast: 'Create a podcast',
  by: 'by',
  goToPodcast: 'Go To Podcast',
  goToAudio: 'Go To Audio',
  cardOwner: 'Card owner',
  goBack: 'Go back',
  editEpisode: 'Edit episode',
  editAudio: 'Edit audio',
  podcastUpdateTitle: 'Podcast Updated',
  audioSeriesUpdateTitle: 'Audio Series Updated',
  podcastUpdateDescription: 'Podcast has been updated successfully',
  audioSeriesUpdateDescription: 'Audio Series has been updated successfully',
  podcastDeletePrompt: ({ type }) =>
    `Are you sure you want to delete this ${type}?`,
  podcastDeleteTitle: 'Delete podcast',
  audioSeriesDeleteTitle: 'Delete audio series',
  podcastDeleteDescription:
    'We have received your request for podcast deletion, we will contact you soon',
  audioSeriesDeleteDescription:
    'We have received your request for audio series deletion, we will contact you soon',
  podcastCreationTitle: 'Podcast created',
  audioSeriesCreationTitle: 'Audio Series created',
  podcastCreationDescription:
    "Your podcast has been created, let's upload the first episode.",
  audioSeriesCreationDescription:
    "Your audio series has been created, let's upload the first episode.",
  episodeUpdateSuccess: 'Episode details updated successfully',
  audioUpdateSuccess: 'Audio details updated successfully',
  publicEpisodeError:
    'You can not publish more public episodes than the private episodes',
  publicEpisodeInfo:
    'Remember that there is a limit on the number of public episodes you are allowed to publish.',
  moreInfo: ' More info here',
  episodePublishedTitle: 'Episode Published',
  audioPublishedTitle: 'Audio Published',
  episodePublishedDescription:
    'Your episode has been published/scheduled. Your community is going to love it!',
  audioPublishedDescription:
    'Your audio has been published/scheduled. Your community is going to love it!',
  publishPastDateError: 'Publish time is in past',
  dateTimeError: 'Please choose correct date and time',
  fileSizeError: 'File size should not be greater than 300 MB',
  episodeNameLabel: 'Episode name',
  audioNameLabel: 'Audio name',
  episodeNameError: 'Please enter name',
  episodeNameRequiredError:
    'Episode name required before uploading episode file',
  whatKindOfContent: 'What kind of content you want to create?',
  recurringMonthlyPayment: 'Recurring monthly payment',
  oneSinglePayment: 'One single payment',
  podcastNameLabel: 'Podcast name',
  audioSeriesNameLabel: 'Audio Series name',
  podcastNameError: 'Please enter name',
  maxCharLimit: ({ length }) => `Max ${length} characters`,
  maxLimitError: 'Maximum length has been exceeded',
  episodeFileError: 'Please upload file',
  episodePromoFileError: 'Please upload promo episode file',
  episodeDescriptionLabel: 'Episode description',
  audioDescriptionLabel: 'Audio description',
  episodeDescriptionError: 'Please enter description',
  episodePrivateNoteLabel: 'Private notes',
  episodePrivateNoteError: 'Please enter private note',
  podcastDescriptionLabel: 'Podcast description',
  audioSeriesDescriptionLabel: 'Audio Series description',
  podcastDescriptionError: 'Please enter the description',
  podcastCoverLabel: 'Upload podcast cover art',
  audioSeriesCoverLabel: 'Upload Audio Series cover art',
  episodeCoverLabel: 'Upload episode cover art',
  audioCoverLabel: 'Upload audio cover art',
  podcastCoverError: 'Please upload cover art',
  episodeCoverError: 'Please upload episode cover',
  applePodcastSizeRecommendation:
    'We recommend a 3000x3000 pixel cover to comply with Apple Podcasts guidelines.',
  releaseDateLabel: 'Release date',
  episodeTypeLabel: 'Type of episode',
  audioTypeLabel: 'Type of audio',
  episodeTypeError: 'Please select type',
  episodeFileTitle: 'Upload episode',
  audioFileTitle: 'Upload audio',
  uploadingMessage: 'Uploading audio file',
  uploadedMessage: 'Uploaded',
  promoDurationWarning:"WARNING: The promo must not be longer than 30 seconds.",
  promoTitle: '(Optional) Promo for the public feed',
  audioSeriesPromoTitle: '(Optional) Promo for non-paying visitors',
  promoKnowledgeLink: 'https://help.mumbler.io/docs/en/18136050-promo',
  podcastCategoryLanguageHeading: 'Podcast category and language',
  audioSeriesCategoryLanguageHeading: 'Audio Series category and language',
  podcastCategoryError: 'Please select category',
  podcastLanguageError: 'Please select a language',
  podcastCategoryPlaceholder: 'Category',
  podcastContentPlaceholder: 'Content',
  podcastLanguagePlaceholder: 'Language',
  episodesIsRequired: 'The episodes are required',
  chooseInsertionPoint: 'Choose insertion point',
  chooseEpisode: 'Choose episode',
  noOptionFound: 'No option found',
  sentEpisodeEmailLabel: "I don't want to send the new episode email.",
  sentAudioEmailLabel: "I don't want to send the new audio email.",
  emailMessageHeading: "Write the email's message for your subscribers",
  emailMessageHeadingAudio: "Write the email's message for your purchasers",
  emailMessageLabel: "Email's message",
  emailMessageError: "Please enter email's message",
  welcomeMessageHeading: 'Write a welcome message to your new subscribers',
  welcomeMessageHeadingAudioSeries:
    'Write a welcome message to your new purchaser',
  welcomeMessageLabel: 'Welcome message',
  welcomeMessageError: 'Please enter the welcome message',
  monthlyPriceHeading: 'Monthly Price',
  priceHeading: 'Price',
  monthlyPriceLabel: 'Subscription price',
  monthlyPriceError: 'Please enter price',
  priceMaxError: ({ price, currency }) =>
    `Price should not be more than ${currency}${price}`,
  priceMinError: ({ price, currency }) =>
    `Price should not be less than ${currency}${price}`,
  customURLHeading: 'Your custom URL',
  slugError: 'Please enter the slug',
  slugErrorMessage: 'Slug should contain alphanumeric characters',
  slugLabel: 'Your podcast name',
  slugLabelAudioSeries: 'Your audio series name',
  slugEpisodeLabel: 'Your episode name',
  slugAudioLabel: 'Your audio name',
  publicRssHeading: "Podcast's Public RSS Feed",
  publicRssDescription: 'This feed only includes the Trailer & Public episodes',
  privateRssHeading: "Podcast's Private RSS Feed",
  privateRssDescription: 'This feed includes private episodes.',
  socialLinksHeading: 'Where to find you',
  listenNowOn: 'Listen now on',
  subcribe: 'Subscribe',
  purchaseAudio: 'Purchase',
  onlyForSubscribersSubscribeNow:
    'Only available for subscribers. Subscribe now!',
  onlyForCustomerPurchaseNow:
    'Purchase this audio. And enjoy this amazing audio content.',
  cardExpiredMessage:
    'Your credit card has expired or has some issues. Please update your bank card before subscribing to a new podcast.',
  cardExpiredMessageAudioSeries:
    'Your credit card has expired or has some issues. Please update your bank card before purchasing to a new audio.',
  subscribeTitle: `We’re excited to see that you want to subscribe`,
  purchaseTitle: `Good to know you want to purchase`,
  subscribeDescription: `We will use the card information saved in your profile. Please confirm your subscription.`,
  purchaseDescription: `Please confirm your purchase.`,
  podcastWelcomeDialogTitle: "You're done! Now spread the word.",
  podcastWelcomeDialogMessage: ({ name }) =>
    `If you want to help ${name} even more, share why you purchased.`,
  podcastShareMessage: ({ link }) =>
    `I just purchased this amazing audio ${link}`,
  episodeDeletePrompt: 'Are you sure you want to delete this episode?',
  episodeDeleteTitle: 'Delete episode',
  audioDeleteTitle: 'Delete audio',
  episodeDeleteSuccessfully: 'Episode deleted successfully',
  keepSubscription: 'Keep subscription',
  subscribeButton: 'Subscribe',
  purchaseButton: 'Purchase',
  unsubscribeButton: 'Cancel subscription',
  landingPageOptions: 'Landing page options',
  hideSubscribeButtons: 'Hide subscription buttons.',
  hidePurchaseButtons: 'Hide purchase buttons.',
  subscriptionButtonIsNotVisibleUntilYouUploadYourFirstAudio:
    'Subscription button is not visible until you upload your first audio',
  updateCreditCardButton: 'Update my credit card',
  noSubscriptionsNow: 'This podcast does not admit subscriptions temporally.',
  subscribe: ({ podcastPrice, currency, planType }) =>
    `Subscribe ${formatNumber(podcastPrice)}${currency}/${
      planType === 'yearly' ? 'year' : 'month'
    } + VAT`,
  renewSubscribe: ({ podcastPrice, currency, planType }) =>
    `Renew subscribe ${formatNumber(podcastPrice)}${currency}/${
      planType === 'yearly' ? 'year' : 'month'
    } + VAT`,
  purchase: ({ podcastPrice, currency }) =>
    `Purchase ${formatNumber(podcastPrice)}${currency} + VAT`,
  purchaseBtnWithoutPrice: 'Purchase',
  subscribeBtnWithoutPrice: 'Subscribe',
  subscribeBtnAnnualMessage: ({ podcastData, currency }) => {
    const percentSavings = Math.floor(
      ((podcastData?.price * 12 - podcastData?.annual_price) * 100) /
        (podcastData?.price * 12),
    );
    let message = `Or ${formatNumber(
      podcastData?.annual_price,
    )}${currency}/year`;
    if (percentSavings > 0) {
      message += ` (save ${percentSavings}%)`;
    }
    return message;
  },
  upgradeToYearlyText: ({ price, currency }) =>
    `Pay ${formatNumber(price)}${currency}/year`,
  upgradeToYearlySavingMessage: ({ podcastData, currency }) => {
    const diff = podcastData?.price * 12 - podcastData?.annual_price;
    const percentage = Math.floor((diff / (podcastData?.price * 12)) * 100);
    const message = `Save ${formatNumber(
      diff,
    )}${currency}/year (${percentage}%)`;
    if (diff > 0) return message;
    return '';
  },
  planPopupSubscribeMonthlyMessage: ({ podcastData, currency }) =>
    `Subscribe ${formatNumber(podcastData?.price)}${currency}/month + VAT`,
  planPopupSubscribeYearlyMessage: ({ podcastData, currency }) =>
    `Subscribe ${formatNumber(
      podcastData?.annual_price,
    )}${currency}/year + VAT`,
  planPopupSubscribeYearlySavings: ({ podcastData }) => {
    const diff = podcastData?.price * 12 - podcastData?.annual_price;
    const percentage = Math.floor((diff / (podcastData?.price * 12)) * 100);
    if (percentage > 0) return `Save ${percentage}% paying annually`;
    return '';
  },
  createCouponButton: 'Create New Coupon',
  createCouponModalTitle: 'Creating New Coupon',
  createCouponModalCoupon: 'Coupon',
  createCouponModalProduct: 'Product',
  createCouponModalDiscount: 'Discount',
  createCouponModalDiscountType: 'Discount Type',
  createCouponModalRedemptions: 'Redemptions',
  createCouponModalDuration: 'Duration',
  createCouponModalMonths: 'Months',
  createCouponModalSubmit: 'Submit',
  percentage: 'Percentage',
  flat: 'Flat',
  once: 'Once',
  repeating: 'Repeating',
  lifetime: 'Lifetime',
  select: 'Select',
  chooseYourPlan: 'Choose your plan',
  dontSendNewEpEmail: "Don't send new episodes email",
  stopChargingUser: 'Stop Charging User',
  allowUserToAccessContent: 'Allow user to access content',
  freePodcastConfirmationMessage: `Changing it to free podcast will remove all your existing subscribers. 
    If you want to keep existing subscibers please add price to the podcast.`,
  products: 'Products',
  pleaseCompleteThePodcastInformation:
    'Please complete the podcast information.',
  becomePRO: 'Become a PRO',
  toPublishFreeProducts: 'to publish free products',
  otherEpisodes: 'Other episodes',
  otherCategoryEpisodesYouMayAlsoLike: ({ category }) =>
    `Other ${category} episodes you may also like`,
  lastUpdatedEpisodesOnMumbler: 'Last updated episodes on Mumbler',
  feedCopiedInYourClipboard: 'Feed copied in your clipboard',
  theCodesDoNotAllowSpacesOrSpecialCharacters:
    'The codes do not allow spaces or special characters.',
  importPodcast: 'Import podcast',
  importYourPodcast: 'Import your podcast',
  importText:
    'Importing your podcast from a feed is the easiest way to migrate your podcast and all its episodes to Mumbler.',
  copyAndPasteYourRSSLink: 'Copy and Paste your RSS link',
  copyAndPasteYourRSSLinkText:
    'Copy the RSS feed link from your original website and paste it into the field below.',
  pasteYourPodcastRSSFeedLinkHere: 'Paste your podcast RSS feed link here',
  import: 'Import',
  rssFeedLinkIsRequired: 'RSS feed link is required',
  importInProgress: 'Import in Progress',
  thisMayTakeSomeTimePleaseComeBackLater:
    'This may take some time. Please come back later.',
  importCancel: 'Import Cancel',
  rssFeedImportCanceledSuccessfully: 'RSS feed import canceled successfully.',
  weHaveSentYouAnEmailToTheAddressSpecifiedInTheFeedToValidateThatThePodcastBelongsToYou:
    'We have sent you an email to the address specified in the feed to validate that the podcast belongs to you.',
  confirmFeedImport: 'Confirm Feed Import',
  confirmFeedImportMessage:
    'Feed email validation is complete, and the data is ready for import. Please confirm to proceed with the feed import. If this action was not initiated by you, you can cancel to stop the process.',
  proceedWithImport: ' Proceed with Import',
  cancelImport: 'Cancel Import',
  validationLinkExpired: 'Validation Link Expired',
  validationLinkExpiredMessage:
    'This feed validation link has expired. Please re-import the feed to start the validation process again.',
  goToImportFeed: 'Go to Import Feed',
  accept: 'Accept',
  almostThere: 'Almost There',
  importSuccessMessage:
    'We have imported all the available information from the feed. Now, please edit the podcast to add any missing details.',
  finalStep: 'Final Step',
  finalStepDescription:
    'To ensure that the episodes you publish on Mumbler are shown on all platforms, you need to add the new RSS feed on your platforms profiles.',
  copyNewRSS: 'Copy new RSS',
  rssImportFailed: 'RSS Import failed',
  rssImportFailedDescription:
    'Your podcast import could not be completed. Please contact us in the support chat.',
  contact: 'Contact',
  youAreAlreadyImportingAFeed: "You're Already Importing a Feed",
  youAreAlreadyImportingAFeedDescriprion:
    'Multiple feeds cannot be imported at the same time. Please wait for the current import to complete.',
  gotIt: 'Got it',
  transcription: 'Transcription',
  showTheTranscriptToAllUsers: 'Show the transcript to all users.',
  aiTranscription: 'AI Transcription',
  aiTranscripting: 'Transcribing',
  aiTranscribed: 'AI Transcribed',
  transcriptionFailed: 'Transcription failed',
  audioTranscriptionStartedSuccessfully:
    'The transcription has started. It may take a while (a 1h episode usually takes about 10 min.). While you wait, you can leave this page and return later.',
  aiTranscriptionCredits: 'AI Transcription Credits',
  audioSizeError: 'Audio size error',
  audioSizeErrorDescription: 'The audio size is too long to be transcribed.',
  youConsumedOutOfTotal: ({ consumed, total }) =>
    `You have consumed ${consumed} minutes of a total of ${total} minutes included in your plan.`,
  subscriptionRenewalAndTranscriptionCreditsUpdate: ({ renewal }) =>
    `Your subscription renews at ${renewal} and your transcription credits will be updated.`,
  thisAudioWillConsume: ({ consumed }) =>
    `The transcription of this audio will consume ${consumed} minutes.`,
  areYouSureYouWantToTranscribeTheAudio:
    'Are you sure you want to transcribe the audio?',
  youDontHaveEnoughCreditsAvailableDoYouWantToUpgradeYourPlan:
    'You don’t have enough credits available. Do you want to upgrade your plan?',
  continue: 'Continue',
  update: 'Upgrade',
  cancel: 'Cancel',
  audioTranscriptionOnlyForPro:
    'Audio AI Transcription is available only for Paid user. Please subscribe to paid plan to use AI Transcription.',
  beforeTranscribingPublishEpisode:
    'Before starting the transcription, please publish the episode. This way, we ensure that you don’t lose your information while the transcription is being processed.',
  uniquePlays: 'Unique plays',
  playsByCountry: 'Plays by country',
  playsByPlayer: 'Plays by player',
  playsByPlatform: 'Plays by platform',
  playsByOS: 'Plays by OS',
  listeners: 'Listeners',
  listenersIsTheAverageNumberOfUniquePlaysInTheFirst24HoursOfTheLast3Episodes:
    'Listeners is the average number of unique plays in the first 24 hours of the last 3 episodes.',
  thirdParty: 'Third party',
  days: ({ days }) => `${days} days`,
  allTime: 'all time',
  onlyIncludesDataSince: 'Only includes data since 10.06.24',
  viewGeneralMetrics: 'View general metrics',
  creatingNewInsertion: 'Creating New Insertion',
  editInsertion: 'Edit Insertion',
  whereToInsert: 'Where to insert',
  submit: 'Submit',
  uploadAudio: 'Upload audio',
  audioInsertAtRequired: 'Please select insert at position',
  audioInsertionCreateSuccess:
    'Your campaign is already set up. You can deactivate it whenever you want in the campaign list.',
  inProgress: 'In progress',
  inviteRecommendationFeatureWarning: " You need to set a default mail app in your Mac's System Settings for the invitation system to work properly.",
  failed: 'Failed',
  scheduled: 'Scheduled',
  published: 'Published',
  onhold: 'On hold',
  audioInsertionDeleteMessage:
    'You’re about to delete the campaign. Are you sure?',
  metricsNotAdd: 'Metrics don’t include Spotify data.',
  both: 'Both',
  malformedAuthentication: 'Malformed authentication',
  podcastUrlNotAllowedToBeAddedByTheUser:
    'Podcast URL not allowed to be added by the user.',
  spotifyDuplicateNameOrUrlError: ({ showUrl, duplicateName }) =>
    `The podcast conflicts with another podcast already on Spotify. Either by URL or name. ${
      showUrl ? `Show Url: ${showUrl}` : ''
    } ${showUrl && duplicateName ? '&' : ''} ${
      duplicateName ? `duplicate name: ${duplicateName}` : ''
    }`,
  userNotAllowedToRetrieveStatusOfPodcast:
    'User not allowed to retrieve status of podcast.',
  podcastNotFound: 'Podcast not found',
  successfullyRemovedThePodcast: 'Successfully removed the podcast.',
  podcastUrlNotAllowedToBeRemovedByTheUser:
    'Podcast URL not allowed to be remove by the user.',
  awaitingIngestion: 'Awaiting ingestion',
  ingestedButFailedValidation: 'Ingested but failed validation',
  ingestedAndValidIfNotAvailableInClientContactUs:
    'Ingested and valid, if not available in spotify, contact us',
  showIsIngestedButTheValidationStatusIsUnknownDueToTooManyRequestsToGetValidationStatusTryAgainLater:
    'Show is ingested, but the validation status is unknown due to too many requests. To get validation status try again later.',
  promoCodeHelpText:
    'Once your promo code is created, copy the link by clicking on "Get link" and share it with your audience. The discount is applied automatically, so customers don’t have to enter the promo code, preventing any mistakes.',
  theProvidedCouponIsInvalidOrUsedUp:
    'The provided coupon is invalid or used up.',
  userAlreadySubscribedToThisPodcast:
    'User already subscribed to this podcast.',
  uploadPromo: 'Upload Promo',
  selectRecommendation: 'Select recommendation',
  recommendations: 'Recommendations',
  promoUploadedSuccessfully: 'You have successfully uploaded the promo.',
  youCanUploadOnlyOnePromoPerDay: 'You can upload only one promo per day.',
  promoEditedSuccessfully: 'You have successfully edit the promo.',
  promoDeactivatedSuccessfully: 'You have successfully deactivated the promo.',
  recommendationForPro:
    "Recommendation are only available for paid users. Please subscribe to a paid plan to upload promo or recommend other's promo.",
  youHaveSuccessfullyAddedRecommendation:
    'You have successfully added recommendation. It may take a few minutes to be heard in your episodes.',
  youHaveNotSelectedAnyRecommendationYet:
    'When you select a promotion from another podcast, it is automatically inserted right before your episodes. You have not selected any recommendation yet.',
  selectedRecommendationDescription:
    "You have selected the following recommendation. If you'd like to change it, please remove and select another.",
  cantFindPodcast:
    "Can't find the podcast you want to recommend? Invite the podcaster to Mumbler. You need to set a default mail app in your System Settings for the invitation system to work properly.",
  yourSelectedRecommendation: "Your Selected Recommendation",
  invite: 'Invite',
  mailToInviteSubjectRecommendation: 'I want to recommend your podcast',
  mailToInviteBodyRecommendation: `Hi,\n\n I want to recommend your podcast, but I can't find it on Mumbler.\n\n Join Mumbler here: https://mumbler.io`,
  youHaveSuccessfullyRemovedRecommendation:
    'You have successfully removed recommendation.',
  status:"Status",
  audioDurationMustNotExceed30Seconds: 'Audio duration must not exceed 30 sec.',
  pleaseWaitForRssImportToCompleteYouCanUploadPromoOnceImportIsCompleted:
    'Please wait for RSS import to complete. You can upload Promo once import is completed.',
  pleaseWaitForRssImportToCompleteYouCanSelectRecommendationOnceImportIsCompleted:
    'Please wait for RSS import to complete. You can select recommendation once import is completed.',
  missingDetails: 'Missing details',
};
en.registerLogin = {
  resetYourPassword: 'Reset Your Password',
  dontForgetToUpdateItOnAllYourPodcastApps:
    "Don't forget to update it on all your podcast apps.",
  startEnjoyingThisPodcastWithStatus: ({ isForSubscriptionPurpose }) =>
    `Start enjoying this ${isForSubscriptionPurpose} podcast`,
  startEnjoyingThisAudioWithStatus: ({ isForSubscriptionPurpose }) =>
    `Start enjoying this ${isForSubscriptionPurpose} audio`,
  startYourSubscriptionPodcast: 'Start your subscription podcast',
  startYourAudio: 'Start your audio',
  registerAndChoosePassword: 'Register and Choose Password',
  start: 'Start',
  iDontWantDonationsIWantClientsPayingForMyEpisodes:
    'When you start using Mumbler you notice that it has been created by podcasters and for podcasters.',
  letsGo: `Let's go!`,
  youAreAlmostThere: 'You’re almost there!',
  congratsYouHaveAlreadyCreatedYourMumblerAccountJustOneStepMoreToSuscribeToYourFirstPodcast:
    'Congrats! You have already created your Mumbler account. Just one step more to purchase your first audio.',
  welcome: 'Welcome',
  goInside: 'Go inside',
  recoverYourPassword: 'Recover your password',
  enterYourMailAndWeWillSendYouALinktoResetYourPassword:
    'Enter your mail and we will send you a link to reset your password',
  emailVerificationInProgress: 'Email verification in progress',
  pleaseWaitWhileWeVerifyYourEmail: 'Please wait while we verify your email.',
  alreadyHaveAccount: 'Do you have an account? Log in',
  spotifyPartnershipDescription:
    'To listen this content on Spotify, you need to make the payment on Mumbler: the podcast hosting service that goes above and beyond',
};
en.sitemap = {
  subscribeCardDetails: 'Subscribe - Card Details',
  podcatcher: 'Podcatcher',
  contact: 'Contact',
  help: 'Help',
  receipts: 'Receipts',
  creditCard: 'Credit Card',
  publicPodcast: 'Public Podcast',
  registerAsAPodcaster: 'Register as a Podcaster',
  registerAsAPodcasterStep2: 'Register as a Podcaster - Step 2',
  podcasterLogin: 'Podcaster Login',
  'forgotPassword?': 'Forgot Password?',
  editProfile: 'Edit Profile',
  unsubscribe: 'Unsubscribe',
  myMumbler: 'My Mumbler',
  createNewPodcast: 'Create New Podcast',
  singlePodcastDetail: 'Single Podcast Detail',
  allEpisodes: 'All Episodes',
  singleEpisodeDetail: 'Single Episode Detail',
  newEpisode: 'New Episode',
  home: 'Home',
};
en.table = {
  name: 'Name',
  since: 'Contact Since',
  sinceMonths: 'Months as a Subscriber',
  purchaseDate: 'Purchase Date',
  status: 'Status',
  pleaseAddEpisodesByClickingNewEpisodeButtonAbove:
    'Please add episodes by clicking New Episode button above',
  pleaseAddAudioByClickingAddAudioButtonAbove:
    'Please add audio by clicking Add Audio button above',
  noData: 'No data!',
  subscribed: 'Subscribed',
  unsubscribed: 'Unsubscribed',
  unsubscribedAccess: 'Unsubscribed with access',
  subscriptionType: 'Interval',
  monthly: 'Monthly',
  annual: 'Annual',
  used: 'Used',
  actions: 'Actions',
  edit: 'Edit',
  remove: 'Remove',
  getLink: 'Get Link',
  date: 'Date',
  length: 'Length',
  view: 'View',
  sentDate: 'Sent',
  createDate: 'Created',
  tags: 'Tags',
  email: 'Email',
  opened: 'Opened',
  clicked: 'Clicked',
  openRate: 'Open Rate',
  clickThroughRate: 'Click-Through Rate',
  views: 'Views',
  amount: 'Amount',
  sinceMonthsAsDonors: 'Months as a Donors',
  active: 'Active',
  inactive: 'Inactive',
  donationDate: 'Donation date',
  unsubscribeDate: 'Unsubscribe date',
  country: 'Country',
  downloads: 'Downloads',
  player: 'Player',
  percentageSymbol: '%',
  os: 'OS',
  episodeName: 'Episode name',
  uniquePlays: 'Unique plays',
  startDate: 'Start date',
  finishDate: 'Finish date',
  plays: 'Plays',
};
en.team = {
  team: 'TEAM',
  meetOurTeam: 'Meet our team',
  cofounder: 'Co-founder',
  designer: 'Product designer',
  podcaster: 'Podcaster pro',
  pol: 'Podcaster at Planeta M, Tribucasters and BUS. Entrepreneur.',
  jose: 'Podcaster at En.Digital, Tribucasters and BUS. Entrepreneur.',
  victor:
    'Podcaster at No es asunto vuestro (It`s not your business) and Nordic Wire. Guidedoc.tv founder.',
  carolina: 'Product designer.',
  weArePodcastersWhoWantToMakeALivingFromPodcastingAndHelpOthersToDoSo:
    'We’re podcasters. We want to make a living from our podcasts and help you to do the same.',
  ourAdvisors: 'Our Advisors',
  thoseWhoHelpToTakeMumblerToTheNextLevel:
    'Helping us bring Mumbler to the next level',
  'wantToStartAPremiumPodcast?': 'Start your subscription-based podcast',
};
en.privacyPolicy = {
  privacyPolicy: 'Mumbler Privacy Policy',
  effectiveDate: 'Effective Date: October 27, 2020',
  welcomeMessage: 'Welcome to Mumbler!',
  mumblerInfo: `Mumbler is a platform where listeners can subscribe to independent podcasts. This Privacy Policy applies to podcasters, listeners and all users of our platform, and is part of our Terms of Use.`,
  mumblerInfo2: `Mumbler is a global company. By using our platform, you agree that personal information that you provide to us, or that we collect through your use of the platform, may be transferred to and stored in the UE and handled as described in this Policy.`,
  preamble: 'Preamble',
  preambleInfo: `Mumbler, in compliance with the provisions of Spanish Law 34/2002 on Information Society Services and Electronic Commerce (hereinafter LISSEC), hereby notifies the User, prior to the rendering of services, of the procedures and obligations required for the execution of this agreement:`,
  preamblePoint: `The User undertakes to read the contents of these General Terms and Conditions and Privacy Policy (hereinafter the Conditions) carefully.`,
  preamblePoint2: `The User undertakes to enter the personal details and any additional information required for the service to be provided.`,
  preambleInfo2: `Mumbler guarantees, by means of the contents of the conditions, to comply fully with applicable consumer protection legislation in order to protect the legitimate interests of users throughout the entire process of contracting the products, services, content and/or tools offered via the website.`,
  informationCollected: `Information You Provide Through Your Account`,
  informationCollectedInfo: `The information that you provide to us through text fields, such as your name, payment information and benefits. The information we collect differs depending on if you make an account, become a listener, or become a podcaster.`,
  informationCollectedPoint: `First and Last Name`,
  informationCollectedPoint2: `Email address`,
  informationCollectedPoint3: `Username`,
  informationCollectedPoint4: `Password`,
  informationCollectedPoint5: `Country of Residence.`,
  informationCollectedInfo2: `You may also sign up using a Facebook or Google account. We will ask permission to access basic information from your Facebook or Google account, such as your name, email, and profile picture. You can choose to stop sharing that information with us at any time by going to Facebook or Google to remove Mumbler's access to that account and your ability to log in.`,
  informationCollectedInfo3: `You also have the option to add more information to your public profile, such as a location, social media links, and an about section, which we store along with any comments or messages you submit on Mumbler.`,
  listeners: `Listeners`,
  listenersInfo: `A listener is someone who joins Mumbler's membership platform to pay for podcaster's membership content. As a listener you must provide your payment information to our payment processors. You can see the privacy policy for these payment processors on the Stripe site. At this time, Mumbler does not receive your full card number. Payment processors provide us with a token that represents your account, your card's expiration date, card type and the last four digits of your card number. If you are required to provide your name and email address to the payment processor, then they also provide us with that information.`,
  listenersInfo2: `We collect and process information about the podcasters you are subscribed to.`,
  podcasters: `Podcasters`,
  podcastersInfo: `A podcaster is someone who creates and provides content for their listeners through Mumbler's membership platform. To become a podcaster, you must create a page that describes your membership podcast. To receive payouts you have to create an account. We may also require your bank account information to process payouts. You must also provide us with additional information for tax purposes. Depending on your location you may have to fill out a form with some combination of your:`,
  podcastersPoint: `First and Last Name`,
  podcastersPoint2: `Address`,
  podcastersPoint3: `Country of citizenship`,
  podcastersPoint4: `Foreign tax identification number`,
  podcastersPoint5: `Bank account information for payouts`,
  additionalInformationCollected: `Additional Information We Collect`,
  additionalInformationCollectedPoint: `Automatically Collected Information`,
  additionalInformationCollectedPointInfo: `We collect information automatically as you navigate the site or through our third party analytics providers. We may store usage information such as the type of device you use to access Mumbler, your operating system, browser type, IP address, and device ID, the pages you visit or request, links clicked, referring sites, user interactions and your search terms. We also derive your location from your self-disclosed country, your IP address, and from your payment card.`,
  additionalInformationCollectedPoint2: `Blogs`,
  additionalInformationCollectedPoint2Info: `We may collect and store your email address, your comments, and your profile information from our blog sites to better understand your interests and improve our services to you.`,
  informationByElse: `Information Collected by Third Parties`,
  cookies: `Cookies`,
  cookiesInfo: `We use Cookies on our website that collect information to allow us and third parties to understand your use of Mumbler. Please see our cookie policy for more information on our use of cookies on Mumbler.`,
  usingInformation: `How We Use Your Information`,
  usingInformationInfo: `We process your information to:`,
  usingInformationPoint: `verify your identity to comply with international laws`,
  usingInformationPoint2: `allow you to sign in to your account`,
  usingInformationPoint3: `allow you to support a podcaster's membership program on Mumbler`,
  usingInformationPoint4: `process membership payments and payouts`,
  usingInformationPoint5: `send you emails relevant to your usage, as controlled by your email preferences`,
  usingInformationPoint6: `reply to your questions`,
  usingInformationPoint7: `provide to you existing and enhanced services related to your Mumbler account`,
  usingInformationPoint8: `spotlight and celebrate podcasters`,
  usingInformationPoint9: `understand how you use the service, and create better tools for podcasters to serve listeners`,
  usingInformationPoint10: `conduct research and development to improve Mumbler and develop future products`,
  usingInformationPoint11: `prevent fraud and abuse on Mumbler`,
  usingInformationPoint12: `provide you with reasonable accommodation, if you notify us of a disability`,
  sharingInformationWithPodcaster: `Information We Share with podcasters`,
  sharingInformationWithPodcasterInfo: `By becoming a listener of a podcaster, you agree to have the following information shared with that podcaster:`,
  sharingInformationWithPodcasterPoint: `your name and email address, and other profile information you've provided`,
  sharingInformationWithPodcasterPoint2: `any messages you send podcasters through Mumbler`,
  sharingInformationWithPodcasterPoint3: `all information about your subscription, including amount and start date, but not your full payment card information`,
  sharingInformationWithPodcasterPoint4: `some aggregated and anonymized data about how you use Mumbler that cannot be linked back to you or to any individual user`,
  sharingInformationWithElse: `Information We Share with Third Parties`,
  sharingInformationWithElseInfo: `We never sell your information to third parties. We will only share data with third parties, other than with podcasters, under the following circumstances:`,
  sharingInformationWithElsePoint: `with our service providers, who are companies that are contractually engaged with us to provide us with services, such as order fulfillment, email management, analyzing data trends, credit card processing, multi currency settlement solutions, increasing our brand awareness and user engagement with marketing initiatives, and fraud detection and prevention. These companies may have access to your data to perform their services, and are obligated by contract to safeguard any of your data they receive from us to the same extent that Mumbler protects it.`,
  sharingInformationWithElsePoint2: `to protect the security or integrity of Mumbler, and to protect the rights, property, or safety of Mumbler, its employees, users, or others, if we believe that disclosure is reasonably necessary to comply with a law, regulation, or other valid legal process (e.g., subpoenas or warrants served on Mumbler). If we are going to release your data, we will do our best to provide you promptly with notice by email, unless we are prohibited by law from doing so.`,
  sharingInformationWithElsePoint3: `in connection with the sale, merger, bankruptcy, sale of assets or reorganization of our company. We will notify you if a different company receives your data. The promises in this privacy policy apply to any data transferred to a new entity.`,
  informationCollectedFromElse: `Information Mumbler Collects from Third Party Apps`,
  informationCollectedFromElseInfo: `When you create a Mumbler account, you can elect to connect your social account(s) (e.g. Twitter, Facebook, Google, YouTube, Twitch) with Mumbler, and we will collect and store some social information from those platforms, such as:`,
  informationCollectedFromElsePoint: `follower or subscriber counts,`,
  informationCollectedFromElsePoint2: `post or upload counts,`,
  informationCollectedFromElsePoint3: `view, like, and comment counts.`,
  informationCollectedFromElseInfo2: `This social information allows us to provide you a better Mumbler experience, and also helps guide future development of Mumbler. We use this data to:`,
  informationCollectedFromElsePoint4: `help podcasters and listeners find each other on Mumbler`,
  informationCollectedFromElsePoint5: `assess how to make podcasters more successful on Mumbler`,
  informationCollectedFromElsePoint6: `analyze and describe our business`,
  preferenceAndRights: `Your Preferences and Rights over Data`,
  choosingPreference: `Choosing Your Preferences`,
  choosingPreferenceInfo: `The Settings link is located by clicking on your avatar or profile at the top right hand of your screen, after your log into your Mumbler account. Settings lets you see your account preferences. You can see and adjust your settings by viewing your preferences and, if you wish, by changing your selections.`,
  marketingActivities: `Marketing Activities`,
  marketingActivitiesInfo: `By agreeing to our terms of use, you expressly agree that Mumbler may:`,
  marketingActivitiesPoint: `provide you with information about your service, service enhancements, or new Mumbler products, while you are on our web site or using our apps.`,
  marketingActivitiesPoint2: `send you messages regarding your existing service, or enhancements related to your existing service, when you are off our platform, via email, or via text, if you have signed up for a service or event notification that uses text messages.`,
  marketingActivitiesPoint3: `send you messages related to services we provide which are separate from your existing service via email, from which you may opt out.`,
  marketingActivitiesPoint4: `market Mumbler to you and audiences similar to you based on your networks and common factors that others have with you, unless you opt out`,
  marketingActivitiesPoint5: `send you marketing emails or texts if you don't have a Mumbler account but have consented to receiving such messages, from which you may opt out.`,
  marketingActivitiesPoint6: `ask for demographic information to spotlight and celebrate podcasters`,
  marketingActivitiesPoint7: `promote your account for greater discoverability`,
  optOutOfMarketing: `Opting out of Marketing`,
  optOutOfMarketingInfo: `You may object to the use of your data for marketing purposes at any time, free of charge, when we collect your personal information and in every marketing communication. You may also object to the use of your data for marketing upon creation of your account or anytime thereafter.`,
  optOutOfMarketingInfo2: `Opting out will stop marketing emails. Please allow up to 30 days for your opt-out request to be processed. If you have an account with Mumbler, you will continue to receive service-related emails, and texts, if you have opted into receiving texts. You will also continue to receive service-related shipments of benefits to the designated delivery address.`,
  optOutOfMarketingInfo3: `We will never sell your information to third parties for marketing purposes without your prior consent.`,
  accountInformation: `Accessing, Updating or Exporting the Information in Your Account`,
  accountInformationInfo: `You can access and update certain information associated with your account on the settings pages. Additionally, you can delete your account, or export your personal information.`,
  deletingAccount: `Deleting Your Account`,
  deletingAccountInfo: `You may self-initiate a deletion of your account. Please be aware that this is a final act and cannot be reversed. If you would like to transport your data, please be sure to download your data first, before you delete your account.`,
  turnOffEmails: `Turning off Email Notifications`,
  turnOffEmailsInfo: `You can change your email notifications in your email settings. While this allows you to stop many notification emails, we will still send some critical service emails.`,
  verificationRequests: `Verification of Requests`,
  verificationRequestsInfo: `Users maintain password protected accounts with Mumbler. If you submit a request to exercise your privacy rights, you will be asked to verify the request by logging into your Mumbler account.`,
  verificationRequestsInfo2: `If we are unable to verify your request, then for your protection, we reserve the right to deny the request.`,
  complianceWithLaw: `Compliance with EU Privacy Laws and Privacy Shield`,
  complianceWithLawInfo: `Mumbler is an international company that complies with the General Data Protection Regulation (GDPR). We act as Data Controllers, as defined under the GDPR, and process personal data based on our legitimate interests, and for contractual necessity in providing you the Service as described in "How We Use Your Information." We also process and share personal information based on notification and users' consent, which our users may revoke at any time.`,
  dataRights: `Exercising Your Data Rights`,
  dataRightsInfo: `Users have rights under the General Data Protection Regulation (GDPR) regarding data that Mumbler controls as a Data Controller as defined under the GDPR, including:`,
  dataRightsPoint: `the right of access to their personal data;`,
  dataRightsPoint2: `the right to correct or rectify any inaccurate personal data;`,
  dataRightsPoint3: `the right to restrict or oppose the processing of personal data;`,
  dataRightsPoint4: `the right to erase or delete their personal data;`,
  dataRightsPoint5: `the right to personal data portability; and`,
  dataRightsPoint6: `the right to opt-out of the sale of personal information.`,
  dataRightsInfo2: `You can exercise rights over your data on Mumbler in the following ways:`,
  dataRightsPoint7: `accessing, reviewing, modifying, and updating your data by logging into your account at Mumbler and going to your account settings.`,
  dataRightsPoint8: `opting out of marketing.`,
  dataRightsPoint9: `going to FAQ support online.`,
  dataRightsPoint10: `If you are unable to log into your account, and are unable to recover your account with a password reset in order to lodge your privacy request, then you may reach out to privacy@mumbler.com. We reserve the right to decline you access to or recovery of your account, at our discretion, to prevent an unauthorized takeover of your account.`,
  discriminationProhibition: `Prohibition against Discrimination`,
  discriminationProhibitionInfo: `We do not discriminate against users who exercise their privacy rights.`,
  dataRetention: `Our Data Retention Period`,
  dataRetentionInfo: `We retain your account information for ten years after your account is last active, unless you delete, or you request us to delete, your account. We may continue to retain some information, even after you delete your account if we are required to do so in order to comply with various laws.`,
  security: `Security`,
  securityInfo: `The security of your personal information is important to us and we follow industry standards to protect it.`,
  children: `Children`,
  childrenInfo: `Mumbler is not directed at children under the age of 13, and children may not create an account or otherwise use Mumbler.`,
  changes: `Changes`,
  changesInfo: `We may sometimes make changes to this policy. If we make material changes that adversely affect your rights under this policy, we will let you know by posting an announcement on the site or sending you an email in advance of the changes coming into effect. Continuing to use Mumbler after a change to this policy means you accept the new policy.`,
  termsOfUse: `Terms of use`,
  termsOfUseInfo: `Mumbler is a membership platform that enables podcasters to be paid by their listeners. One of our core behaviors is to put podcasters first, and these terms attempt to do that.`,
  mumblerInfo3: `By using Mumbler you agree to these terms and to the other policies we post. Please read them carefully and let us know if you have any questions. For information about our data practices, please see our Privacy Policy, including our Cookie Policy. We can collect and use your information in accordance with those policies.`,
  yourAccount: `Your account`,
  yourAccountInfo: `When you create an account you must provide us with accurate information, in good faith, and you agree to keep your information updated if it changes.`,
  yourAccountInfo2: `To create an account you must be at least 13 years old. To join a podcaster's membership as a listener, or provide a membership as a podcaster, you must be at least 18 years old or have your parent's permission.`,
  yourAccountInfo3: `You are responsible for anything that occurs when anyone is signed in to your account, as well as the security of the account. Please contact us immediately if you believe your account is compromised. You can learn more about security on our Security Policy page.`,
  abusiveConduct: `Abusive conduct`,
  abusiveConductInfo: `You are responsible for all activity on your account. If you violate our policies we may terminate your account.`,
  abusiveConductInfo2: `Don't do anything illegal, abusive towards others, or that abuses our site in a technical way. If you are a podcaster raising funds on Mumbler, we may be held accountable for what you do with those funds. As a result, we also look at what you do with your membership off our platform.`,
  abusiveConductInfo3: `These policies cover most issues, but if you find a new and creative way to hurt Mumbler or our community we may take action to prevent it.`,
  aboutBeingPodcaster: `All about being a podcaster`,
  aboutBeingPodcasterInfo: `A podcaster is someone who offers membership to listeners on Mumbler. There are a lot of details here involving payments, fees, taxes and restrictions that you should read in full if you are a podcaster.`,
  membership: `Membership`,
  membershipInfo: `To become a podcaster simply launch your page to start your membership. Memberships are for your most passionate listeners. You're inviting them to be part of something exciting and listeners pay on a subscription basis.`,
  payments: `Payments`,
  paymentsInfo: `As a podcaster you make your membership available on Mumbler, and we provide membership to your listeners on a subscription basis. We also handle payments issues such as fraud, chargebacks and resolution of payments disputes.`,
  paymentsInfo2: `We try to provide timely access to your funds, but you may occasionally experience delays in accessing your funds. We may also block or hold payments for violations of our policies or for compliance reasons, including collecting tax reporting information. When payments are delayed or blocked we try to communicate the reason to you promptly. If you have questions about a payments block, please reach out to us. In order to protect podcasters, we may block listeners' payments if we believe them to be fraudulent.`,
  paymentsInfo3: `Sometimes activities like refunds can put your account balance into the negative. If your balance becomes negative then we reserve the right to recover those funds from future payments.`,
  fees: `Fees`,
  feesInfo: `Mumbler keeps a small commission from all of the payments made by your subscribers. The total commission we apply is 10% + 30cents per transaction (payment you receive). This fees includes everything:`,
  feesPoint: `Payment costs: includes the payment gateway’s fee to charge subscribers, international card fees, collection reattempt charges, fees for changing a subscriber’s card when it expires, combating fraud…`,
  feesPoint2: `Mumbler's comission: that we use to pay for the servers, improve the product, provide technical support, pay the team...`,
  tax: `Tax`,
  taxInfo: `We collect tax identification information and report this to tax authorities as legally required. You are responsible for reporting any income or withholding taxes which may be due as a result of payments received.`,
  taxInfo2: `We are additionally responsible for a number of transactional taxes across the world. These include but are not limited to VAT on payments for electronically supplied services to listeners in the EU, GST as an operator of an electronic distribution platform (EDP) within Australia, and state sales tax as a result of 'marketplace laws' within the United States. For the purpose of electronically-supplied services, podcasters make a supply of those services to us, and then we supply them to the listener.`,
  taxInfo3: `In locales where Mumbler is required to collect transaction tax from listeners, pledges processed from listeners will reflect the tier or custom pledge amount plus the applicable tax. Applicable tax will not be taken from a pledge, but rather will be charged in addition to the pledge. Once settled, the tax amount will be automatically deducted for remittance to the applicable taxing authority.`,
  taxInfo4: `As a podcaster on our site, it is your responsibility to provide accurate information when you are engaging with the sales tax weighting tool and, generally, when listing benefits transferred within your tiers.`,
  restrictions: `Restrictions`,
  restrictionsInfo: `We don't allow content that violates our policies. Here you have a summary of our rules:`,
  restrictionsPoints: `Illegal content.`,
  restrictionsPoints2: `Content that are abusive towards other people.`,
  restrictionsPoints3: `Content that use others' intellectual property, unless you have written permission to use it, or your use is protected by fair use.`,
  restrictionsInfo2: `If your listeners include people under the age of 18, then please remind them that they need permission to join your membership, and those under the age of 13 cannot use Mumbler. We are not required to allow any particular person or group of persons to be your listener.`,
  restrictionsInfo3: `As a podcaster you are also responsible for keeping listener data safe.`,
  restrictionsInfo4: `An account is tied to your creative output and cannot be sold or transferred for use by another podcaster.`,
  aboutBeingListener: `All about being a listener`,
  aboutBeingListenerInfo: `To become a listener simply create an account, add your payment details and join a podcaster's membership!`,
  aboutBeingListenerInfo2: `The amount of each membership subscription depends on the podcaster you support. You can see the details as you join, as well as in the receipt sent to you after each successful payment. You can view all your active membership subscriptions and billing history on your "my Mumbler" page.`,
  aboutBeingListenerInfo3: `You may cancel your membership payments at any time. Terminating the tier support of membership will go into effect in the subsequent (not current) payment term.`,
  aboutBeingListenerInfo4: `Canceling your payment may result in your loss of access to a podcaster's podcast.`,
  aboutBeingListenerInfo5: `These include when you cancel your membership subscription, your payment method fails, the podcaster blocks you, or the podcaster deletes their account. We are not required to allow you to be a listener of any particular podcaster.`,
  aboutBeingListenerInfo6: `Podcasters' memberships vary and we have limited control over the quality and specific offerings. We attempt to screen for fraudulent podcaster pages, but cannot guarantee the identity of podcasters or the validity of any claims they make. We appreciate your help reporting suspicious podcaster pages so we can keep Mumbler safe.`,
  aboutBeingListenerInfo7: `If you are located in the jurisdiction in which Mumbler is required to charge and collect tax (e.g. VAT or, sales tax), then this tax is added to the total charge. Mumbler remits all tax collected to the applicable taxing authority. As tax is largely dependant on your location, you are responsible for keeping your address complete and up to date.`,
  aboutBeingListenerInfo8: `Depending on your location, some banks may charge you a foreign transaction fee for your membership subscription. Mumbler does not control this charge, but it is typically around 3% . Please contact your bank for more information.`,
  refunds: `Refunds`,
  aboutBeingListenerInfo9: `. Our policy is No Refunds, though we will allow for some exceptions where refunds are granted in our sole discretion.`,
  mumblerRole: `Mumbler's role`,
  mumblerRoleInfo: `We proactively look at some pages and posts on Mumbler to make sure podcasters follow our terms and conditions. We also investigate reports of potential violations. These investigations may take a while to resolve and may include looking at what is supported by funds received through Mumbler.`,
  mumblerRoleInfo2: `In most situations we will work with podcasters to resolve any potential violations and allow the podcaster to continue using Mumbler. Terminating accounts is not an action we take lightly and is done in only the most extreme cases.`,
  mumblerRoleInfo3: `Please let us know if you see potential violations of our Terms and conditions.`,
  mumblerRoleInfo4: `As a global company based in Spain with operations in other countries, we must comply with economic sanctions and trade restrictions.`,
  mumblerRoleInfo5: `We are constantly testing out new features with the goal of making Mumbler better. We may add or remove features, and often test features with a random subset of our community. If we believe a feature is significantly different from these terms, then we explain those differences in the test.`,
  mumblerRoleInfo6: `With your permission, we may give other websites or services the ability to verify information about your Mumbler account or perform actions on your behalf. This permission is asked for when you connect your Mumbler account to these other websites or services. You can learn more in our Privacy Policy.`,
  accountDeletion: `Account deletion`,
  accountDeletionInfo: `You can permanently delete your account at any time.`,
  accountDeletionInfo2: `We can terminate or suspend your account at any time at our discretion. We can also cancel any membership subscriptions and remove any descriptions, posts or benefits at our discretion.`,
  accountDeletionInfo3: `You may not bring a claim against us for suspending or terminating another person's account, and you agree you will not bring such a claim. If you try to bring such a claim, you are responsible for the damages caused, including attorneys fees and costs.`,
  accountDeletionInfo4: `These terms remain in effect even if you no longer have an account.`,
  yourCreation: `Your creations`,
  yourCreationInfo: `By posting creations on Mumbler you grant us a royalty-free, perpetual, irrevocable, non-exclusive, sublicensable, worldwide license to use, reproduce, distribute, perform, publicly display or prepare derivative works of your creation. The purpose of this license is strictly limited to allow us to provide and promote memberships to your listeners. We will never try to steal your creations or use them in an exploitative way.`,
  yourCreationInfo2: `You may not post creations that infringe others' intellectual property or proprietary rights.`,
  yourCreationInfo3: `listeners may not use creations posted by podcasters in any way not authorized by the podcaster.`,
  mumblerCreation: `Mumbler's creations`,
  mumblerCreationInfo: `Our creations are protected by copyright, trademark and trade secret laws. Some examples of our creations are the text on the site, our logo, and our codebase. We grant you a license to use our logo and other copyrights or trademarks to promote your Mumbler page.`,
  mumblerCreationInfo2: `You may not otherwise use, reproduce, distribute, perform, publicly display or prepare derivative works of our creations unless we give you permission in writing. Please ask if you have any questions.`,
  indemnity: `Indemnity`,
  indemnityInfo: `You will indemnify us from all losses and liabilities, including legal fees, that arise from these terms or relate to your use of Mumbler. We reserve the right to exclusive control over the defense of a claim covered by this clause. If we use this right then you will help us in our defense.`,
  indemnityInfo2: `Your obligation to indemnify under this clause also applies to our subsidiaries, affiliates, officers, directors, employees, agents and third party service providers.`,
  warranty: `Warranty disclaimer`,
  warrantyInfo: `We do our best to make sure Mumbler works as expected, but stuff happens.`,
  warrantyInfo2: `Mumbler is provided "as is" and without warranty of any kind. Any warranty of merchantability, fitness for a particular purpose, non-infringement, and any other warranty is excluded to the greatest extent permitted by law.`,
  warrantyInfo3: `The disclaimers of warranty under this clause also apply to our subsidiaries, affiliates and third party service providers.`,
  liability: `Limit of liability`,
  liabilityInfo: `To the extent permitted by law, we are not liable to you for any incidental, consequential or punitive damages arising out of these terms, or your use or attempted use of Mumbler. To the extent permitted by law, our liability for damages is limited to the amount of money we have earned through your use of Mumbler. We are specifically not liable for loss associated with unfulfilled benefits and from losses caused by conflicting contractual agreements.`,
  liabilityInfo2: `For this clause "we" and "our" is defined to include our subsidiaries, affiliates, officers, directors, employees, agents and third party service providers.`,
  dispute: `Dispute resolution`,
  disputeInfo: `To summarize: If you have a problem please talk to us, but you are limited in how you can resolve disputes. You waive your right to trial by jury and your right to participate in a class action proceeding.`,
  disputeInfo2: `We encourage you to contact us if you have an issue. If a dispute does arise out of these terms or related to your use of Mumbler, and it cannot be resolved after you talk with us, then it must be resolved by arbitration.`,
  disputeInfo3: `This clause does not limit either party's ability to file an action in a court with jurisdiction to seek injunctive or other equitable relief for disputes relating to intellectual property, proprietary data or to enforce this dispute resolution clause, including your agreement not to assert claims related to the suspension or termination of another person's account. In any such action, the court rather than an arbitrator must decide whether such a claim is arbitrable and must decide whether the party is entitled to the requested injunctive or other equitable relief.`,
  governingLaw: `Governing law`,
  governingLawInfo: `Spanish law, excluding its conflict of law provisions, governs these terms and all other Mumbler policies. If a lawsuit does arise, both parties consent to the exclusive jurisdiction and venue of the courts located in Madrid, Spain.`,
  everythingElse: `Everything else`,
  everythingElseInfo: `These terms and any referenced policies are the entire agreement between you and us, and supersede all prior agreements. If any provision of these terms is held to be unenforceable, then that provision is modified to the extent necessary to enforce it. If a provision cannot be modified, it is severed from these terms, and all other provisions remain in force. If either party fails to enforce a right provided by these terms, it does not waive the ability to enforce any rights in the future.`,
  everythingElseInfo2: `We may sometimes make changes to these terms and policies. If we make material changes that adversely affect your rights, then we will let you know before the changes come into effect. Continuing to use Mumbler after a change means you accept the new terms or policies.`,
  everythingElseInfo3: `If you use accessibility tools and have questions or concerns, please contact us.`,
  cookiePolicy: `Cookie Policy`,
  cookiePolicyInfo: `A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. This small text file enables a site to remember your actions and preferences for a specified period of time, so you don't have to re-enter your login or settings information each time you visit a new page on our site.`,
  useOnMumbler: `Use on Mumbler`,
  useOnMumblerInfo: `Cookies are not used to identify you personally and are not used for any purpose other than those described here.`,
  securityAndAuth: `Security / Authentication:`,
  securityAndAuthInfo: `Some cookie and similar technology functions are necessary and vital to ensuring that`,
  securityAndAuthInfo2: `Mumbler works properly for visitors and members, such as maintaining the security, safety, and integrity on Mumbler, authentication and logging in (including remembering permissions and consents you have granted), and ensuring the ability to securely complete transactions.`,
  performance: `Performance / Analytics and Research:`,
  performanceInfo: `Some technologies help provide performance data on how Mumbler is functioning in order to make improvements to the platform. Data collected on site and app functionality and speed, how Mumbler is used and detecting and gathering reporting on bugs helps improve Mumbler and the services we offer.`,
  preference: `Preferences / Localization:`,
  preferenceInfo: `Cookies enable your personalized views and settings. Some cookies help us provide localized experiences &mdash; for example, by making sure you see Mumbler in your preferred language.`,
  socialNetworks: `Social Networks / Marketing:`,
  socialNetworksInfo: `Some technologies help you to interact with social networks you are signed into while using Mumbler, such as logging in with the social network and other features you employ with the social network, or that are allowed in the social network's privacy policy. These may be set and controlled by the social networks, and your preferences with those social networks.`,
  doNotTrack: `"Do-not-track" technologies`,
  doNotTrackInfo: `We do not respond to web browser "Do-Not-Track" signals.`,
  manageCookie: `How to manage cookies`,
  manageCookieInfo: `You can control the use of cookies at the individual browser level. If you reject or delete cookies, our Services may no longer function as intended. Each browser provides different mechanisms for managing cookies. Look at your browser's help menu to determine the best way to modify your browser's cookie storage.`,
  changesToCookiePolicy: `Changes to Cookie Policy`,
  changesToCookiePolicyInfo: `We periodically update this Cookie Policy to account for changed legal and operational circumstances, to describe new cookies and tracking technologies, and to describe how those changes affect our use of your information. If we are going to use information in a manner that is materially different from that stated at the time of collection or make any material changes in our privacy practices, we will notify you. We will post those changes through a prominent notice on our website.`,
  changesToCookiePolicyInfo2: `This policy is part of Mumbler's Terms of Use and Privacy Policy. For more information, contact Mumbler.`,
};
en.podcatcher = {
  listenPodcatcher: 'How to listen on your podcatcher',
  copyRSSLink: 'Copy Public RSS Link',
  copyPrivateRSSLink: 'Copy Private RSS Link',
  copied: 'Copied!',
  apple:
    'To listen to the podcast on Apple Podcast go to “Library / Edit / Add podcast with URL” and add the private RSS link.',
  overcast:
    'To listen to the podcast on Overcast go to: “ + / ADD URL” and add the private RSS link.',
  pocketCasts:
    'To listen to the podcast on Pocket Casts, go to "Magnifying Glass Icon > Paste the Feed > Click on the Podcast > Subscribe."',
  google:
    'To listen to the podcast on Google Podcast go to: "Home / More / Link icon / Paste private RSS feed".',
  podcastGuruDescription:
    'To listen to the podcast on PodcastGuru go to "Main Menu > Tools > Import Podcast from RSS > Paste RSS".',
  PodcastAddictDescription:
    'To listen to the podcast on PodcastAddict go to "Plus icon on the main menu > Search icon on the main menu > Paste RSS > Check private on the popup ".',
};
en.faq = {
  faq1: `If I create a podcast on Mumbler, will all of my content be restricted subscribers?`,
  faq1Info: `Mumbler is designed so you can monetize your audience. But we know it is useful to share part of the content publicly. For this reason, on Mumbler, we allow you to create `,
  faq1InfoBold: `3 different types of episodes`,
  faq1Info2: ` which will have a different visibility:`,
  faq1PointBold: `Private episodes:`,
  faq1Point: ` only available to your subscribers.`,
  faq1Point2Bold: `Trailer episodes:`,
  faq1Point2: `highlighted on your podcast page on Mumbler so anyone can find out what your podcast is about and what to expect if they subscribe.`,
  faq1Point3Bold: `Public episodes:`,
  faq1Point3: `they can be listened to by any visitor to your page on Mumbler and on the podcatchers on which you add your public RSS.`,
  faq1Info3: `To maintain the essence of Mumbler, which is focused on creating paid podcasts, only 50% of your episodes can be public. If you have already published 50% of public episodes, you will have to create a new private episode before uploading a public one.`,
  faq2: `Can I create various podcasts within the same Mumbler account?`,
  faq2Info: `Yes, there are no limits to the number of podcasts that you can create within Mumbler. The creation of a podcast on Mumbler is also free of charge, so feel free to create as many podcasts as you like.`,
  faq3: `How do I receive the money I earn with the subscribers of my podcast?`,
  faq3Info: `On your podcast’s control panel, you will see the money that you have built up thanks to the payments from your subscribers. When you have `,
  faq3Bold: `accumulated more than €50`,
  faq3Info2: `, you will be able to ask us to send your money. To do so, you just need to click on the “Request payment” button and you will receive your money in a few days. You can only make `,
  faq3Bold2: `one payment request per month`,
  faq3Info3: ` (for now).`,
  faq3Info4: `The limit of €50 is necessary to minimize the impact of the money transfer fees.  If the amount was lower, we would have to increase the commission that we apply to cover the transfer fee.`,
  faq4: `How does Mumbler earn money?`,
  faq4Info: `Mumbler keeps a small commission from all of the payments made by your subscribers. The total commission we apply is 10% + 30cents per transaction (payment you receive). This fees includes everything:`,
  faq4PointBold: `Payment costs:`,
  faq4Point: ` includes the payment gateway’s fee to charge subscribers, international card fees, collection reattempt charges, fees for changing a subscriber’s card when it expires, combating fraud…`,
  faq4Point2Bold: `Mumbler's comission:`,
  faq4Point2: ` that we use to pay for the servers, improve the product, provide technical support, pay the team...`,
  faq5: `If I want to leave Mumbler someday, can I take my subscribers? What happens to the public feed?`,
  faq5Info: `The subscribers and content `,
  faq5Bold: `are yours.`,
  faq5Info2: ` If, someday, you want to leave Mumbler, write to us at `,
  faq5Email: ({ email }) => email,
  faq5Info3: ` and we will coordinate the transfer of subscriber information with you and we will also set up a redirecting of your public feed so you don’t lose contact with them on the way.`,
  faq6: `Why use Mumbler instead of creating my own membership site on WordPress or any other system?`,
  faq6Info: `Creating a membership site for a paid podcast is no trivial task. It requires advanced technical knowledge or a considerable investment in the development of a bespoke site.`,
  faq6Info2: `Mumbler simplifies the process of creating a private podcast to the maximum. In just a few clicks and a few minutes, you can have your podcast working, `,
  faq6Bold: `without technical knowledge`,
  faq6Info3: ` or headaches. This way, you can focus on generating the best content for your audience.`,
  faq7: `How does the RSS work on Mumbler?`,
  faq7Info: `On Mumbler we generate `,
  faq7Bold: `two types of RSS`,
  faq7Info2: ` for each podcast:`,
  faq7PointCommon: `A `,
  faq7PointBold: `public RSS`,
  faq7Point: ` with the free episodes that you can share on any podcatcher to gain a bigger audience.`,
  faq7Point2Bold: `private RSS`,
  faq7Point2: ` for each subscriber of your podcast which contains the free and paid episodes. If a subscriber stops paying, the private RSS stops working.`,
  faq8: `Does Mumbler offer hosting for my podcast?`,
  faq8Info: `Yes, hosting for your podcast is included and you don't have to pay for it.`,
};
en.testimonial = {
  title: 'Already in Mumbler',
  abel: 'When you start using Mumbler you realize that the platform has been created by and for podcasters.',
  claudio:
    'If you ask me why I chose Mumbler, the answer is simple as its interface. I was conquered by its transparency and the focus of its creators to improve and knowing who is behind each subscription.',
};

en.creator = {
  creatorPage: 'Creator Page',
  myPodcastsAndAudioSeries: 'My podcasts and audio series',
  emailAddress: 'Email address',
  name: 'Name',
  thisIsWhatOthersHaveToSay: 'This is what others have to say',
  publishCreatorPage: 'Publish Creator Page',
  creatorName: 'Creator Name',
  creatorNameLabel: 'Creator name that you want to display',
  emptyCreatorNameError: 'Please Enter Creator Name',
  longCreatorNameError: 'Creator Name can not be more than 20 characters',
  formCtaTitle: 'Please Enter Title',
  formCtaText: 'Please Enter Text',
  aboutTitleError: 'Please Enter Title',
  aboutTextError: 'Please Enter Text',
  domain: 'Domain',
  domainError: 'Please enter the domain',
  domainErrorMessage: 'Domain name should be valid',
  domainLabel: 'Creator Name',
  copyDomainURL: 'Copy Domain URL',
  formCTA: 'Form CTA (Call to action)',
  title: 'Title',
  text: 'Text',
  button: 'Button',
  doNotShowTheForm: 'Don’t show the form',
  aboutSection: 'About Section',
  username: 'Username',
  testimonialTweets: 'Testimonial Tweets',
  pasteEmbedTweet: 'Paste Embed Tweet',
  showNewsletter: 'Show newsletter',
  showNewsletterLabel: 'Show last newsletter editions on my creator page',
  brand: 'Brand',
  btnColor: 'Button color',
  txtColor: 'Text color',
  creatorCreationTitle: 'Creator Created',
  creatorCreationDescription: 'Creator has been created successfully',
  creatorUpdateTitle: 'Creator Updated',
  creatorUpdateDescription: 'Creator has been updated successfully',
  subscribedSuccess: 'Subscribed Successfully',
  termsError: 'Please accept our Terms and Conditions',
  ctaTextMaxLimitError: "This field can't have more than 200 characters",
  aboutTextMaxLimitError: "This field can't have more than 500 characters",
  donations: 'Donations',
  donationLabel: 'I want to accept donations on my creator page.',
  donateHelpText: 'Let the visitors know why to donate…',
  helpLabel: 'Help text',
  donationTitle: ({ name }) => `Help ${name} keep creating`,
  cool: 'Cool!',
  confirmYourDonationPlease: 'Confirm your donation please.',
  goAhead: 'Go ahead',
  yourMonthlyDonationIsAlreadyActiveForThisCreator:
    'Your monthly donation is already active for this creator. If you want to change it, please go to My Feed → My dontations and stop the actual donation.',
  goToMyFeed: 'Go to My Feed',
  thanksForDonating: 'Thanks for donating',
  helpWithPrice: ({ price, currency }) => `Help with ${price}${currency}`,
  helpWithPricePerMonth: ({ price, currency }) =>
    `Help with ${price}${currency}/month`,
  puntual: 'Punctual',
  monthly: 'Monthly',
  donationAmountShouldNotBeLessThan1:
    'Donation amount should not be less than 1',
  donationAmountShouldNotBeMoreThan500:
    'Donation amount should not be more than 500',
  youDoNotHaveAnyActiveMonthlyDonation:
    "You don't have any active monthly donation",
  youHaveNotSubscribedToAnyCreator: "You haven't subscribed to any creator",
};

en.newsletter = {
  publicationName: 'Publication Name',
  publicationNameLabel: 'Your newsletter name',
  emptyPublicationNameError: 'Please Enter Publication Name',
  longPublicationNameError: 'Creator Name can not be more than 50 characters',
  description: 'Short Description',
  descriptionLabel: 'Title',
  emptyDescriptionError: 'Please Enter Short Description',
  longDescriptionError: 'Short Description can not be more than 250 characters',
  category: 'Category',
  categoryError: 'Please select category',
  select: 'Select',
  noOptionFound: 'No option found',
  language: 'Language',
  languageError: 'Please select language',
  welcomeEmail: 'Welcome email',
  welcomeEmailInfo:
    'Email sent to readers immediately after they become a free subscriber',
  dontSendWelcomeEmail: `Don't send a welcome email`,
  squareLogo: 'Square logo',
  squareLogoInfo: 'Should be at least 256x256 pixels',
  emailSenderName: 'Email sender name',
  emailSender: 'Email sender email',
  emailSenderEmailInfo:
    'Recipients of your emails will see this as the sender.',
  emptySenderNameError: 'Please Enter Email Sender Name',
  emptySenderEmail: 'Please enter sender email',
  emailsHeader: 'Emails header',
  emptyEmailsHeaderError: 'Emails header can not be empty',
  emailsHeaderInfo: 'Header for all newsletters',
  enterTheCopy: 'Enter the copy',
  emailsFooter: 'Emails footer',
  emptyEmailsFooterError: 'Emails Footer can not be empty',
  emailsFooterInfo: 'Footer for all newsletters',
  newsletterCreationTitle: 'Newsletter Settings',
  newsletterCreationDescription:
    'Newsletter Settings has been added successfully',
  newsletterUpdateTitle: 'Newsletter Settings',
  newsletterUpdateDescription:
    'Newsletter Settings has been updated successfully',
  previewNewsletter: 'Preview Newsletter',
  pleaseSubscribeToProPlan: 'Please subscribe to PRO plan.',
  contactUsForMoreAudience:
    'You have reached maximum audience limit, please contact us.',
  goToPlans: 'Go to plans',
  contactUs: 'Contact Us',
  youHaveSuccessfullyUnsubscribed: 'You have successfully unsubscribed.',
  eventTrackingDataUpdatesInSomeInterval:
    'Event tracking data updates in some interval.',
  couponOnlyForPro:
    'Coupons are only available for PRO users. Please subscribe to PRO plan to create new coupons.',
  audioInsertionForPro:
    'Audio insertions are only available for PRO users. Please subscribe to PRO plan to create new audio insertions.',
  deliveries: 'Deliveries',
  opens: 'Opens',
  ctr: 'CTR',
  newEdition: 'New Edition',
  editEdition: 'Edit Edition',
  writeYourEdition: 'Write your edition',
  editionDetails: 'Edition Details',
  pleaseEnterTitle: 'Please enter title',
  characterLimitReached: ({ limit }) => `${limit} character limit reached`,
  newsletterTitle: 'Newsletter Title',
  linkURL: 'Link URL',
  goToNewsletterSettingPage:
    'Newsletter settings are not defined, please go to Settings --> Newsletter',
  successful: 'Successful',
  emailSentSuccessfully: 'Email sent successfully',
  pleaseEnterTheDescription: 'Please enter the description',
  describeThisNewsletterEdition: 'Describe this newsletter edition',
  sendTestEmail: 'Send test email',
  tagsCanNotBeEmpty: 'Tags can not be empty',
  includedAudience: 'Included Audience',
  pleaseAddSubjectAndContentForWelcomeEmail:
    'Please add Subject and Content for Welcome email',
  deleteEdition: 'Delete Edition',
  delete: 'Delete',
  deleteMessage: 'This will delete newsletter permanently',
  latestEditions: 'Latest newsletter editions',
  by: 'by',
  showNewsletterInCreatorPage: 'Show newsletter in creator page',
  hideNewsletterInCreatorPage: 'Hide newsletter in creator page',
  newsletterVisibilityUpdatedSuccessfully:
    'Newsletter visibility updated successfully',
  beforeSendingAnyNewsletterOrEmailYouShouldDefineYourNewsletterSettingsPlease:
    'Before sending any newsletter or email you should define your newsletter settings please.',
  beforeSendingAnyNewsletterOrEmailYouShouldDefineYourCreatorSettingsPlease:
    'Before sending any newsletter or email you should define your creator settings please.',
  saveAndGoToSettings: 'Save and go to settings',
  pleaseEnterSomeNewsletterContent: 'Please enter some newsletter content',
  seoOptions: 'SEO Options',
  seoTitle: 'SEO title',
  seoDescription: 'SEO description',
  pleaseEnterSeoTitle: 'Please enter SEO title',
  pleaseEnterSeoDescription: 'Please enter SEO description',
  customButtonText: 'Custom button',
  shareButtonText: 'Share',
  subscribeButtonText: 'Subscribe',
  donateButtonText: 'Donate',
  donationButtonTooltip:
    'You cannot add this button because you don’t have donations enabled.',
  emailPreheader:'Preheader',
  longPreheaderError:'Preheader Description can not be more than 100 characters',
  maxPreheaderLimit: ({ length }) => `Max ${length} characters`,
};

en.settings = {
  profile: 'Profile',
  creatorPagePlan: 'Creator / Company Page',
  creatorPage: 'Creator',
  newsletter: 'Newsletter',
  billing: 'Billing',
  plans: 'Plans',
  spotify: 'Spotify',
  subscribe: ({ price, currency }) =>
    `Subscribe ${formatNumber(price)}${currency}/month + VAT`,
  free: 'FREE',
  actual: 'ACTUAL',
  basic: 'BASIC',
  pro: 'PRO',
  plus: 'PLUS',
  ultra: 'ULTRA',
  planPrice: ({ price }) => `${price}€/month`,
  unlimitedAudios: 'Unlimited podcasts & audio series',
  downloadPerMonth: ({ plays }) => `${plays} plays/month`,
  crossPlatformAnalytics: 'Cross-platform analytics',
  distributionOnAllPlatforms: 'Distribution on all platforms',
  episodeScheduling: 'Episode scheduling',
  podcastHosting: 'Podcast Hosting',
  freeAudioProducts: 'Free podcasts & audio series',
  paymentAudioProducts: 'Payment podcasts & audio series',
  fees20: '20% fees',
  subscribersPlan: ({ subscribers }) => `${subscribers} paid subscribers`,
  segmentedEmails: 'Segmented emails',
  contacts: ({ contact }) => `${contact} email contacts`,
  websiteForYourShow: 'Website for your show',
  sendNewsletters: 'Send newsletters',
  audioInsertion: 'Audio insertion',
  donation: 'Donation',
  rssFeedsImport: 'RSS Feeds import',
  transcriptions: 'AI transcriptions',
  unlimitedDownloads: 'Unlimited downloads',
  stripeFees: 'Stripe fees',
  promoCodes: 'Promotion codes',
  minPerMonthAITranscriptions: ({ min }) =>
    `${min} min/month AI transcriptions`,
  pauseSubscriptions: 'Pause subscriptions',
  allowRecommendations:"Cross recommendations",
  hosting: 'Hosting',
  ultraPlan: `Need more? Ask for our <a href="mailto:hello@mumbler.io"><b>ultra</b></a> plan.`,
  sellPodcastsSuscriptions: 'Sell podcasts subscriptions',
  sellOnePaymentAudioSeries: 'Sell one payment audio series',
  mumblerComissionFeeForFreeUser: '10% + 30cts. for each sale',
  mumblerComissionFeeForProUser: '3% + 30cts. for each sale',
  freePodcasts: 'Host free podcasts',
  freeAudioSeries: 'Host free audio series',
  payAsYouGrow: 'Pay as your contacts grow',
  creatorPagePublishWarning:
    'The creator page is not yet published. Please check the “Publish creator page” option to publish it.',
  emailMarketing: 'Email marketing',
  goToPlans: 'Upgrade',
  congratulationsYouHaveReachedTheLimitsOfTheFreePlan:
    "Congratulations! 🚀 You've reached the limits of the free plan.",
  subscribeNowToAPaidPlanToKeepGrowingAndEnjoyAllOurAdvancedFeatures:
    'Subscribe now to a paid plan to keep growing and enjoy all our advanced features.',
  subscribeNow: 'Subscribe now',
  congratulationsYouHaveReachedTheLimitsOfTheBasicPlan:
    "Congratulations! 🚀 You've reached the limits of the BASIC plan.",
  congratulationsYouHaveReachedTheLimitsOfTheProPlan:
    "Congratulations! 🚀 You've reached the limits of the PRO plan.",
  subscribeNowToTheProPlanToKeepGrowingAndEnjoyAllOurAdvancedFeatures:
    'Subscribe now to the PRO plan to keep growing and enjoy all our advanced features.',
  subscribeNowToThePLusPlanToKeepGrowingAndEnjoyAllOurAdvancedFeatures:
    'Subscribe now to the PLUS plan to keep growing and enjoy all our advanced features.',
  congratulationsYouHaveReachedTheLimitsOfThePlusPlan:
    "Congratulations! 🚀 You've reached the limits of the PLUS plan.",
  contactUsToLearnMoreAboutOurUltraPlan:
    'Contact us to learn more about our ULTRA plan.',
  contact: 'Contact',
  warningMessageToShowUserExceedPlanLimits:
    'Your success is growing more and more and the time has come to upgrade your plan.',
  warningMessageToShowUserExceedPlanLimitsInBanner:
    'You have exceeded the limits of your current plan.',
  pleaseUpgradeYourPlanToAHigherTier:
    'Please upgrade your plan to a higher tier.',
  subscriptionEndForOldProPlan: ({ currency, date }) =>
    `You have cancel 9${currency}/month subscription. You can still access Pro features till ${date}`,
  subscribeTo: ({ name }) => `Subscribe to ${name} plan`,
  thenPerMonthStartingOn: ({ currency, price, date }) =>
    `Then ${price}${currency} + VAT per month starting on ${date}`,
  remainingTimeOnAfter: ({ plan, date }) =>
    `Remaining time on ${plan} plan after ${date}`,
  unusedTimeOnAfter: ({ plan, date }) =>
    `Unused time on ${plan} plan after ${date}`,
  proratedThenBilledMonthly: 'Prorated, then billed monthly',
  pricePerMonthAfter: ({ currency, price }) =>
    `${price}${currency}/month after`,
  subtotal: 'Subtotal',
  totalExcludingTax: 'Total excluding tax',
  total: 'Total',
  appliedBalance: 'Applied balance',
  totalDueToday: 'Total due today',
  billsOnForOneMonth: ({ date }) => `Bills on ${date} for 1month.`,
  downgradePrice: ({ price, currency }) => `${price}${currency} + VAT`,
  downgrade: 'Downgrade',
  upgrade: 'Upgrade',
  subscribeButton: 'Subscribe',
  successfullyUnsubscribeFromTheCreatorPlan:
    'Successfully unsubscribe from the creator plan ',
  successfullyUnlinkSpotifyAccount: 'Successfully unlink spotify account.',
};

en.audience = {
  addSubscriber: 'Add Contacts',
  subscriberAddedSuccessfully: 'Subscriber Added Successfully',
  audience: 'Audience',
  editASubscriber: 'Edit a Subscriber',
  addCommaSeparatedTags: 'Add comma separated tags',
  updatedRecords: 'Updated Records',
  skippedRecords: 'Skipped Records',
  csvWillBeUploadedShortly: 'CSV will be uploaded shortly',
  csvImportedSuccessfully: 'CSV Imported Successfully',
  howDoYouWantToImport: 'How do you want to import?',
  manually: 'Manually',
  csv: 'CSV',
  clickHere: 'Click here',
  toDownloadSampleCSVFile: 'to download sample CSV file.',
  addSubscriberManually: 'Add contact manually',
  importCsv: 'Import CSV',
  onlyCsvUploadsSupported: 'Only csv uploads supported',
  pleaseUploadFile: 'Please upload file',
  deleteSubscriber: 'Delete Subscriber',
  deleteSubscriberWarningMessage: ({ email }) =>
    `Are you sure you want to delete this subscriber? (${email})`,
  deleteSubscriberSuccessMessage: 'Subscriber deleted successfully',
  valuesSeparatorInfo:
    'Please be sure that the CSV values are separated by "," or ";"',
  onlyUploadsSupportedType: ({ type }) => `Only ${type} uploads supported`,
  theImportProcessMakeTakeAFewMinutes:
    'The import process make take a few minutes.',
};

en.dashboard = {
  requestForPayment: 'Request for payment',
  yourPaymentHasBeenSuccessfullyInitiated:
    'Your payment has been successfully initiated',
  dashboard: 'Dashboard',
  allContacts: 'All contacts',
  plays: 'Plays',
  customers: 'Customers',
  sales: 'Sales',
  subscribers: `Contacts`,
  claimYourAmount: 'Claim your amount',
  claimYourDonations: 'Claim your donations',
  lastPayment: 'On the way:',
  youCannotClaimPayoutUntil50: 'You cannot claim payout until 50',
  youCanClaimOnlyIfTheAmountIsMoreThan50:
    'You can claim only if the amount is more than 50',
  requestForPayout: 'Request for payout',
  contacts: 'Contacts',
  donations: 'Donations',
};

en.feed = {
  myFeed: 'My Feed',
  feed: 'Feed',
  myPurchases: 'My Purchases',
  myCreators: 'My Creators',
  myDonations: 'My Donations',
  createYourOwnAudienceOnMumbler: 'Create your own audience on Mumbler',
  createYourAudienceSendNewslettersAndSellYourDigitalProductsAsEasyAsItGets:
    'Create your audience, send newsletters and sell your digital products. As easy as it gets.',
  startNow: 'Start now',
  viewMore: 'View More',
  stopDonating: 'Stop donating',
  areYouSureYouWantToStopDonatingNow:
    'Are you sure, you want to stop donating now?',
  cancelSubscription: 'Cancel Subscription',
};

en.startWizard = {
  welcomeToMumbler: 'Welcome to Mumbler!',
  inOrderToOfferYouTheBestExperienceCanYouTellUsWhyDidYouSignUpForMumbler:
    'Glad to see you here! In order to offer you the best experience, can you tell us why did you sign up for Mumbler?',
  enjoyContent: 'Enjoy content',
  publishContentAndCreateAnAudience:
    'Publish content and/or create an audience',
  justLooking: 'Just looking',
  claimYourCreatorPage: 'Claim your creator page',
  chooseYourNameAndClaimYourCreatorPageInMumblerToGrowAnAudienceAndSellYourAudios:
    'Choose a name and claim your creator page to grow an audience and sell your audio.',
  aLittleBitAboutYourself: 'A little bit about yourself',
  allowPeopleToDiscoverMoreAboutYourselfSoTheyWantToSubscribeToYourContents:
    'Allow people to discover more about yourself so they want to subscribe to your contents.',
  hurray: 'Hurray!',
  yourCreatorPageIsUpAndRunning: 'Your creator page is up and running.',
  shareYourPage: 'Share your page',
  goToCreatorPageSettings: 'Go to creator page settings',
  followYourTopCreators: 'Follow your top creators!',
  onMumblerYouCanSubscribeToThePremiumContentAndNewslettersOfYourFavouriteCreators:
    'On Mumbler you can subscribe to the premium content and newsletters of your favourite creators.',
  inThisSectionMyFeedYouWillHaveDirectAccessToTheirLatestContents:
    'In this section, “My Feed”, you will have direct access to their latest contents.',
  discoverNewContentAandCreators: 'Discover new content and creators',
  wantToDiscoverNewCreatorsAnAmazingContents:
    'Want to discover new creators an amazing contents?',
  weGotYouCoveredVisitOurDiscoverPage:
    'We got you covered. Visit our discover page.',
  becomeACreator: 'Become a creator!',
  weTrulyBelieveEveryoneIsACreatorYouToo: `We truly believe everyone is a <b>creator</b>. You too.`,
  youCanOpenYourCreatorPageWheneverYouWantByVisitingYourSettingsPage:
    'You can open your creator page whenever you want by visiting your settings page.',
  lifeIsBetterWhenYouSetUpYourMumblerAccount:
    'Life is better when you set up your Mumbler account',
  setUpYourCreatorPage: 'Set up your creator page',
  setUpYourNewsletter: 'Set up your newsletter',
  importYourEmailDatabase: 'Import your email database',
  createYourFirstProduct: 'Create your first product',
};

en.donation = {
  donations: 'Donations',
  donate: ({ podcastPrice, currency }) =>
    `Donate ${formatNumber(podcastPrice)}${currency}`,
  donatePerMonth: ({ podcastPrice, currency }) =>
    `Donate ${formatNumber(podcastPrice)}${currency}/month`,
};

en.spotify = {
  spotify: 'Spotify',
  distribution: 'Distribution',
  integrations: 'Integrations',
  thisPageShowsYourActiveIntegrations:
    'This page shows your active integrations.',
  spotifyActivateDescription:
    'Submitting your show to Spotify will allow people to discover your content. Make sure you have a title, description, cover art, and have published an initial audio file. Spotify will automatically update when you release a new episode.',
  activate: 'Activate',
  attention: 'Attention',
  activationConfirmationMessage:
    'You are about to publish your podcast on Spotify. Are you sure?',
  publish: 'Publish',
  deactivationConfirmationMessage:
    'You are about to deactivate your podcast, which means it will disappear from Spotify.',
  deactivate: 'Deactivate',
  getStatus: 'Get status',
  thisIsYourSpotifyShowUrl: 'This is your spotify show url',
  spotifyActivationNote:
    "Please note that if you don't see your show at this page it can take upto 48 hours to get an approval from spotify",
  unlinkAccountButton: 'Unlink',
  unlinkAccountDescription:
    'Your Mumbler account is currently linked to Spotify. If you choose to unlink it, all permissions and access between Mumbler and Spotify will be revoked. To regain access, you will need to relink your account by completing the linking process again through Spotify.',
  confirmUnlinkAccount: 'Confirm unlink account',
  unlinkingWillRemoveAllAccessoSpotifyThroughMumblerAndYouWillNeedToRelinkYourAccountToRegainAccess:
    'Unlinking will remove all access to Spotify through Mumbler, and you’ll need to relink your account to regain access.',
  areYouSureYouWantToProceed: 'Are you sure you want to proceed?',
  unlink: 'Unlink',
  accessDenied: 'Access Denied',
  somethingWentWrongYourAccountDoesNotHaveNecessaryPermission:
    'Something went wrong. Your account does not have the necessary permissions to access this content through Spotify Open Access. Please verify your subscription status or contact support for assistance.',
  partnerUserIdLinkedToOtherAccount:
    'This Mumbler user has already been linked to another Spotify user, or the Spotify user you are trying to link the account with has already been linked to another Mumbler user.',
  unlinkingFromMumbler: 'Unlinking from mumbler',
  ifYouWantToUnlinkYourSpotifyAccountFromAMumblerCreator:
    'If you want to unlink your Spotify account from a mumbler creator',
  goToIntegrationTab:
    "Go to the Setting > Integration Tab. You'll need to be logged in to your mumbler account",
  clickUnlinkButton: `Click <strong>Unlink</strong> button under Distribution > Spotify section.`,
  unlinkingFromThirdPartyCreators: 'Unlinking from third-party creators',
  ifYouWantToUnlinkYourSpotifyAccountFromAThirdPartyCreator:
    'If you want to unlink your Spotify account from a third-party creator:',
  goToLinkAccountPage: `Go to the <a href="https://content-access.spotify.com/?_ga=2.232771423.1102209914.1728370804-238180742.1722578633" target="_blank">Linked accounts page</a>. You'll need to be logged in to your Spotify account.`,
  clickUnlinkButtonSpotify: `Click <strong>Unlink</strong> next to the creator’s name.`,
  noteToLinkAgain:
    '<strong>Note:</strong> If you unlink from a creator or publisher, you’ll no longer have access to your subscriber content from this creator on Spotify.',
  somethingWentWrongPleaseContactMumblerByUsingTheWebChat:
    'Something went wrong. Please contact Mumbler by using the web chat.',
  spotifyManuallyAddedNote:
    'If you have manually added RSS feed to Spotify please kindly add Spotify show url to the podcast settings page to make linking flow behave properly.',
};
export default en;
